@import '../../../css/scss/vars';

.time-filter-component {
    @include respond-to(mobile) {
        width: 100%;
    }

    @media (min-width: 768px) {
        width: 170px;
    }

    label {
        margin: 0;
    }
    button {
        height: 36px;
        line-height: normal;
    }
}
