@import 'css/scss/_vars';
@import 'css/scss/token';

.instaconnect {
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 767px) {
      margin-bottom: $sizing_x-small-var;
    }

    @media (min-width: 768px) {
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: 0;

      font-size: 22px;
      text-align: center;
      font-family: $f-regular;
    }

    p {
      font-size: 16px !important; // TODO: remove !important
    }
  }
  .button {
    margin-top: $sizing_x-small-var;
  }

  .text {
    @media (max-width: 767px) {
      margin-bottom: $sizing_x-small-var;
    }

    @media (min-width: 768px) {
      margin-bottom: 25px;
    }
    p {
      margin-bottom: 0;
      text-align: left;
      font-family: $f-light;
      font-size: 13px;
      line-height: 18px;
      & b,
      strong {
        font-family: $f-regular;
        font-weight: bold;
      }
    }
  }
  .error {
    border-radius: $radius;
    background-color: #f8f6f6;
    padding: 20px 15px 20px 20px;
    display: flex;
    font-size: 14px;
    line-height: 16px;
    color: $greyBoulder;
    text-align: left;
    align-items: center;
    margin-bottom: 10px;
    .message {
      padding-left: 15px;
    }
  }
  .warning {
    margin: 15px 10px;
    & p {
      font-size: 13px;
      text-align: center;
      font-family: $f-regular;
      letter-spacing: -0.3px;
    }
  }
  .gnee {
    margin: $sizing_x-small-var !important;
    .link {
      text-decoration: underline;
      color: $greyBoulder;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .checkbox {
    margin-top: $largeSpace;
    text-align: left;
    label {
      margin-bottom: 0;
    }
  }
}
