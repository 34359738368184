@import 'css/scss/_vars2.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;

  .icon {
    position: absolute;
    left: $space;
  }

  .input {
    width: 100%;
    height: 100%;
    padding: $smallSpace $smallSpace $smallSpace 42px;
    background-color: #F8F9FA;
    border: none;
    border-radius: 100px;

    &:focus {
      @include borderFocus;
    }

    &::placeholder {
      color: $grey;
      font-size: 13px;
      font-weight: 400;
      vertical-align: middle;
      transform: translateY(-1px);

      @media only screen and (min-width: $l) {
        font-size: 14px;
      }
    }

    &:disabled,
    &:disabled + label {
      background-color: $disabled;
      cursor: not-allowed;
    }
  }

  &.white {
    .input {
      padding: $smallSpace;
      background-color: $white;

      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }
}
