@import 'css/scss/_vars';

.wrapper {
  background: linear-gradient(90deg,#dcccff, $lightblue);
  height: calc(100vh - 120px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    background: url('/assets/images/Pattern_01.svg');
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0.3;
    @media (max-width: 767px) {
      background-size: 120px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
      background-size: 232px;
    }

    @media (min-width: 1200px) {
      background-size: 355px;
    }
  }

  .card {
    width: 100%;
    margin: 0 $space;
    border-radius: $radius;
    background-color: #fff;
    box-shadow: 0 0 1px 0 rgba(10,31,68,0.1), 0 18px 18px 0 rgba(10,31,68,0.12);
    overflow: hidden;
    position: relative;
    z-index: 1;

    @media (min-width: 768px) {
      width: 420px;
    }

    .formWrapper {
      padding: $largeSpace $largeSpace;
      button {
        margin-top: $space;
      }
    }

    .error {
      display: block;
      color: $errorLabel;
      text-align: left;
      font: 12px/18px $f-D-DinExp-Bold;
      margin-top: $space;
    }

    .success {
      display: block;
      color: $successLabel;
      text-align: center;
      font: 14px/21px $f-D-DinExp-Bold;
      margin-top: $space;
    }
  }

  .title {
    margin: 0 0 $tinySpace 0;
    font-size: 28px;
    line-height: 1.1;
    @include respond-to(mobile) {
      font-size: 22px;
    }
  }

  .subtitle {
    color: rgba($darkblue, 0.6);
    font-size: 14px;
    line-height: 1.5;

    .link {
      color: $lightblue;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .input {
    margin-top: $space;
  }

  .linkButton {
    color: $metalblue;
    font-size: 14px;
    font-weight: 400;
    margin-left: auto;
  }

  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    & > span {
      margin-bottom: $smallSpace;
    }
  }
}