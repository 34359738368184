@import 'css/scss/_vars';

$nbLinesCollapsed: 11;
$nbLinesUncollapsed: 21;
$rowHeight: 40px;
$colWidth: 150px;

.hashtags {
    position: relative;
    margin: 0 0 $largeSpace;
    @media (min-width: $break-mobile) {
        margin: $smallSpace auto 0;
    }

    section {
        padding-bottom: $smallSpace;
        box-sizing: content-box;
        border-radius: $radius;
        background-color: $pureWhite;
        box-shadow: $boxshadow;
        overflow: hidden;

        .content {
            overflow: hidden;
            transition: max-height 0.2s;
            max-height: calc(#{$nbLinesUncollapsed} * #{$rowHeight});
            &.collapsed {
                max-height: calc(#{$nbLinesCollapsed} * #{$rowHeight});
            }
            .table {
                margin: auto;
                width: 97%;
                table-layout: fixed;
                tbody > tr {
                    &:nth-child(even) {
                        background: $pureWhite;
                    }
                    &:nth-child(odd) {
                        background: $lightGrey;
                    }
                }
                tr {
                    height: $rowHeight;

                    > * {
                        text-align: center;
                        color: $darkblue;
                        border: none;
                    }
                    > .column {
                        &Likes,
                        &Comments,
                        &Rate {
                            width: $colWidth;
                            padding: 0;
                        }
                    }

                    th {
                        font-size: 11px;
                        &.columnComments,
                        &.columnLikes {
                            @media (max-width: $break-mobile) {
                                display: none;
                            }
                        }
                    }
                    td {
                        letter-spacing: normal;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 700;
                        &.columnKey {
                            font-weight: normal;
                            text-align: left;
                            font-family: $f-medium;
                        }
                        &.columnComments,
                        &.columnLikes {
                            @media (max-width: $break-mobile) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .toggle {
        width: 100%;
        margin: $smallSpace 0 -12px;
        text-align: center;
        line-height: $largeSpace;

        a {
            display: inline-block;
            font-size: 12px;
            font-weight: 700;
            color: $darkblue;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
            .chevron {
                display: inline-block;
                position: relative;
                width: 10px;
                height: 10px;
                line-height: 10px;
                transition: transform 0.3s ease-in-out;
                top: 2px;
                left: $tinySpace;
                &.chevronUp {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
