@import 'css/scss/_vars';
@import 'css/scss/token';

$sizeCard: 32%;
$flexCard: 31%;

.container {
    position: relative;
    width: 100%;
    border-radius: $radius;
    box-shadow: $boxshadow;
    background-color: #fff;
    padding: 16px;

    &.isButton {
        padding: 0;
    }

    >.content {
        height: 100%;
    }

    @include respond-to(mobile) {
        width: 100%;
        margin: 8px;
    }
    @media (min-width: 768px) and (max-width: 990px) {
        flex: 1 0 $flexCard;
        margin: 5px;
    }
    @media (min-width: 991px) {
        flex: 1 0 $flexCard;
        margin: 8px;
    }

    &.showTopPost {
        box-shadow: none;
        border-radius: 0;
        max-width: 100%;
        margin: 0;

        >.content {
            display: flex;
            align-items: stretch;

            @include respond-to(mobile) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        border-bottom: $border;
    }
    >.expiredInstagram {
        display: flex;
        align-items: center;
        padding: 4px;
        border-radius: $borderRadius_medium;

        background-color: rgba($colorEvolution_down, .1);
        color: $colorEvolution_down;

        width: fit-content;

        @media (min-width: 991px) {
            margin-top: -$spacing_x-small;
        }

        .icon {
            margin: 6px $spacing_xx-small 6px 6px;

            > svg {
               fill: $colorEvolution_down !important;
            }
        }
        .button {
            margin-left: $spacing_xx-small;
        }
    }
}

.animationShell {
    width: 100%;
    position: relative;
    display: flex;
}

.delete {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 10;
    svg {
        fill: #d8d8d8;
    }
}

.deleteButton {
    position: relative;
    padding: 0;
    top: 4px;
    right: 2px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 14px;
    height: 14px;

    svg {
        fill: #d8d8d8;
        width: 12px;
    }
}

.divider {
    margin: 0;
}
