@import '../../../../css/scss/vars';

.bc-sub-menu {
    position: relative;

    &--visible {
        pointer-events: auto;
        opacity: 1;
    }
    display: block;
    opacity: 0;
    pointer-events: none;

    position: absolute;
    top: 30px;
    right: 0;
    z-index: 1000;

    transition: opacity 0.1s ease-out;
}

.bc-sub-menu__content {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    min-width: 227px;

    border-radius: 4px;
    background: white;
    box-shadow: 0 0 4px 0 rgba(0, 48, 87, 0.2);
}

.bc-sub-menu__card {
    display: flex;
    align-items: center;

    border: none;
    cursor: pointer;

    padding: 8px 16px;
    white-space: nowrap;

    color: inherit;
    text-transform: none;
    text-decoration: none;
    font-family: $f-regular;
    font-size: 14px;
    line-height: 16px;
    background-color: transparent;

    &:hover {
        background-color: #f2f4f6;
    }

    &:focus {
        z-index: 1;
    }
}
