@import 'css/scss/_vars2.scss';

.wrapper {
  padding: $space $smallSpace;
  border-bottom: $border;

  @media only screen and (min-width: $m) {
    padding: $space;
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #F8F9FA;
  border-radius: $bigRadius;
  @include resetList();

  @include breakpoint(desktop) {
    max-width: 465px;
    margin: 0 auto;
  }

  .tab {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: $smallSpace;
    color: #638198;
    font-size: 12px;
    cursor: pointer;
    min-width: 100px;

    &.active {
      color: $kolnavy;
      font-size: 14px;
      font-weight: 500;
      background-color: white;
      border-radius: $bigRadius;
      box-shadow: 0 8px 12px rgba(black, 0.16);
    }

    &.disabled {
      color: rgba($kolnavy, 0.5);
      cursor: not-allowed;
    }
  }
}
