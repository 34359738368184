@import 'css/scss/_vars2.scss';

.wrapper {
  position: relative;
  display: flex;
  justify-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: $space;

  .content {
    margin-top: 16px;
    color: #638198;
    display: flex;

    justify-content: center;
    p {
      text-align: center;
      line-height: 20px;
      margin-bottom: 0;
    }

    .statsSharing {
      text-align: center;
      .bubble {
        color: $kolnavy;
        font-size: 14px;
        padding: $space;
        background-color: #F8F9FA;
        border-radius: $bigRadius;
        overflow: hidden;
        margin: $smallSpace 0;
      }
      button {
        margin: $space auto;
      }
    }
  }
}
.timestamp {
  width: 100%;
  color: $kolnavy;
  font-size: 12px;
  text-align: center;
}