@import 'css/scss/_vars';

.container {
    padding: 30px 12px;
    width: 100%;
}

.title {
    @include boxTitle;
}

.subTitle {
    font-size: 12px;
    font-style: italic;
    line-height: 14px;
}
.wrapper {
    margin-top: 30px;
}

.item {
    font-size: 14px;

    &.snapchat {
        svg {
            background-color: $snapchat;
        }
    }
    &.pinterest {
        color: $pinterest;
    }
    &.twentyone_buttons {
        color: $twentyone_buttons;
    }
    &.twitch {
        color: $twitch;
    }
}
.buttonWrapper {
    text-align: center;
    margin-top: 30px;
    .button {
        margin: 0 auto;
    }
}
.empty {
    padding: 0;
    border: none;
    .buttonWrapper {
        margin: 20px 0 80px;
        @include respond-to(mobile) {
            margin-bottom: 32px;
        }
    }
}
