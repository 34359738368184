@import 'css/scss/_vars2.scss';

.wrapper {
  display: flex;
  position: relative;

  .dropdown {
    padding: $space;
    background-color: white;
    border: 1px solid #ccd6dd;
    bottom: 0;
    left: 40px;
    box-shadow: 0 3px 5px 0 rgba($kolnavy, .1);
    width: 256px;
    position: absolute;
    z-index: 2;

    .label {
      color: $kolnavy;
      font-size: 13px;
      display: block;
    }

    .input {
      margin-bottom: $space;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      margin-top: $bigSpace;
      button {
        margin-left: $space;
      }
    }
  }
}
