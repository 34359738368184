@import 'css/scss/_vars2.scss';

.wrapper {
    position: relative;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-right: $smallSpace;
    border-radius: 50%;
    background-color: $kolnavy;
    display: flex;
    align-items: center;
    justify-content: center;
  
    &.hide {
      opacity: 0;
    }

    .initials {
      color: white;
      font-size: 20px;
      text-transform: uppercase;
    }
  
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      position: absolute;
    }
  
    @media only screen and (min-width: $l) {
      width: 40px;
      height: 40px;
    }
  }