@import '../../../css/scss/vars';

$sizeCard: 32%;
$flexCard: 31%;

.container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: $radius;
    background-color: #fff;
    font-size: 11px;
    position: relative;
    user-select: none;
    appearance: none;
    border: none;
}

.addButton {
    @include respond-to(mobile) {
        width: 100%;
        margin: 8px;
    }
    @media (min-width: 768px) and (max-width: 990px) {
        flex: 1 0 $flexCard;
        margin: 5px;
        max-width: $sizeCard;
    }
    @media (min-width: 991px) {
        max-width: $sizeCard;
        flex: 1 0 $flexCard;
        margin: 8px;
    }
}

/* EMPTY */
.empty {
    color: #fff;
    padding-left: 13px;
    padding-right: 5px;
    cursor: pointer;
    height: 100%;
    min-height: 100px;

    &.facebook {
        background: $facebook;
    }
    &.instagram {
        background: $instagram;
        height: 38px;
        padding: 8px 15px;
        .cardText {
            font-family: "Roboto-Medium";
            font-size: 14px;
            line-height: 20px;
        }
        .picto svg {
            width: 22px;
            height: 22px;
        }
    }
    &.youtube {
        background: $youtube;
    }
    &.twitter {
        background: $twitter;
    }
    &.tiktok {
        background: $tiktok;
    }
}

.picto {
    display: flex;
    svg {
        width: 25px;
        height: 25px;
    }
}

.cardText {
    margin: 0 0 0 15px;
    padding: 0;
    line-height: 14px;
    text-align: left;
}
