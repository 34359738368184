@import '../../../css/scss/vars';

.wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    user-select: none;
    appearance: none;
    width: 100%;
    border: none;
    font-family: $f-400;
    font-size: 11px;

    .content {
        width: 100%;
        margin-top: 10px;
        margin-right: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .username {
        display: flex;
        align-items: center;
        width: 100%;

        &.withDelete {
            p {
                width: calc(100% - 25px);
            }
        }

        .delete {
            display: none;
            @include respond-to(mobile) {
                display: block;
            }
        }

        &:hover {
            .delete {
                display: block;
            }
        }
    }

    .loader {
        width: 100px;
        z-index: 1;
        span {
            width: 6px;
            height: 6px;
            background-color: $darkblue;
        }
        > div {
            padding: 0;
        }
    }

    .cardText {
        margin: 0;
        font-size: 12px;
        font-style: italic;
    }

    &.row {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        .username {
            flex: 1 0 180px;
            overflow: hidden;
            margin-right: 8px;
        }

        .content {
            margin-top: 0;
            justify-content: start;
            > div {
                margin-right: 16px;
            }
        }

        @include respond-to(mobile) {
            font-size: 14px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: auto;
            .username {
                flex: 1;
            }
            .content {
                margin-top: 16px;
                justify-content: space-around;
            }
        }
    }
}
