@import '../../../../css/scss/vars';

$sizeCard: 32%;
$flexCard: 31%;

.bc-social-stats-list {
    display: flex;
    flex-direction: column;

    &--row {
        flex-direction: row;
    }
}

.bc-social-stats-list__title {
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    margin: 0 0 $tinySpace 0;
}

.bc-social-stats__subtitle {
    margin: 0;
    color: $subTitle;
    font-size: 10px;
    line-height: 11px;
}

.bc-social-stats-list__social-stats-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    &--row {
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        display: flex;
        align-items: stretch;

        @include respond-to(mobile) {
            margin-left: -7px;
            margin-right: -7px;
        }
        @media (min-width: 768px) and (max-width: 990px) {
            margin-left: -2px;
            margin-right: -2px;
        }
        @media (min-width: 991px) {
            margin-left: -7px;
            margin-right: -7px;
        }

        .bc-social-stats-card.bc-social-stats-card--empty {
            flex: 1 0 $flexCard;
        }

        .bc-social-stats-card .bc-social-stats-card__metric {
            margin: 2px;
            flex: 1 0 35%;
            max-width: $sizeCard;

            .bc-social-stats-card__number {
                font-size: 18px;
            }
            .bc-social-stats-card__label {
                font-size: 9px;
            }
        }
    }
}

.bc-social-stats-list__legend {
    align-self: flex-start;

    color: #4a4a4a;
    font-family: $f-light;
    font-size: 10px;
    line-height: 19px;
}

.bc-social-stats-list__link {
    margin-top: 13px;
    display: inline-block;

    @include respond-to(mobile) {
        margin: 20px auto 34px;

        border: 1px solid #d8d8d8;
        border-radius: 2px;
        background-color: #ffffff;
        text-decoration: none;
    }

    @media (min-width: 768px) {
        margin-left: auto;
        text-decoration: underline $darkblue;
    }
}

.bc-social-stats-list__link-body {
    display: flex;

    color: $darkblue;
    font-family: $f-light;

    @include respond-to(mobile) {
        padding: 10px;
        display: flex;
        align-items: center;

        font-size: 12px;
    }

    @media (min-width: 768px) {
        margin-left: auto;

        font-size: 11px;
    }

    svg {
        fill: $darkblue;

        @include respond-to(mobile) {
            margin-right: 10px;
            width: 15px;
            height: 15px;
        }

        @media (min-width: 768px) {
            display: none;
        }
    }
}
