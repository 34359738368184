@import '../../../css/scss/vars';

.bc-proposition-description-card {
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);

    @media (max-width: 575px) {
        padding: 30px 19px 27px;
    }

    @media (min-width: 576px) and (max-width: 1199px) {
        padding: 26px 30px;
    }

    @media (min-width: 1200px) {
        padding: 37px 50px;
    }
}

.bc-proposition-description-card__title {
    line-height: 18px;

    @media (max-width: 575px) {
        font-size: 17px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 15px;

        font-family: $f-regular;
        color: black;
    }

    @media (min-width: 576px) {
        font-size: 15px;
        margin-bottom: 20px;

        text-transform: uppercase;
        font-family: $f-light;
        color: $greyBoulder;
    }
}

.bc-proposition-description-card__description {
    color: $greyBoulder;
    font-family: $f-light;
    font-size: 13px;
    line-height: 19px;

    @media (max-width: 575px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (min-width: 576px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    &:not(first-of-type) {
        @media (max-width: 575px) {
            margin-top: 15px;
        }

        @media (min-width: 576px) {
            margin-top: 20px;
        }
    }
}

.bc-proposition-description-card__subtitle {
    margin-bottom: 0;

    color: $greyBoulder;
    font-family: $f-regular;
    font-size: 13px;
    line-height: 19px;

    &:before {
        content: ' \25CF';
        font-size: 7px;
        position: relative;
        top: -2px;
        left: -21px;
        margin-right: -4px;
    }
}
