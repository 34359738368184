@import '../../../css/scss/vars';

.bc-home__slide-container {
    display: block;
    padding: 9px 14px 9px 17px;

    background-color: #fff;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba($darkblue, 0.1);
    text-decoration: none;
    border-bottom: 1px solid #e6e6e6;
    color: $darkblue;

    .bc-home__slide-container__slide {
        display: grid;
        padding: 34px;

        @media (max-width: 1199px) {
            grid-template-areas:
                'title'
                'figure'
                'text';
            justify-items: center;
        }

        @media (min-width: 1200px) {
            grid-template-areas:
                'figure title'
                'figure text';
        }
    }

    .bc-home__slide-container__slide__stats {
        grid-area: figure;
        align-self: center;
        justify-self: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 140px;

        @media (min-width: 1200px) {
            min-width: 208px;
        }
        &__cred {
            width: 160px;
            text-align: center;
            @media (min-width: 1200px) {
                font-size: 14px;
            }
        }
    }

    .bc-home__slide-container__slide__title {
        grid-area: title;
        align-self: flex-end;
        text-align: center;

        @media (max-width: 1199px) {
            font-size: 24px;
            line-height: 45px;
        }

        @media (min-width: 1200px) {
            margin: 0 34px 24px;

            font-size: 34px;
            font-weight: normal;
        }
    }

    .bc-home__slide-container__slide__text {
        grid-area: text;
        justify-self: center;
        background-size: cover;
        flex-direction: column;
        text-align: center;

        span {
            font-family: $f-medium;
        }

        &--instagram {
            span {
                color: $instagram;
            }
        }
        &--facebook {
            span {
                color: $facebook;
            }
        }
        &--youtube {
            span {
                color: $youtube;
            }
        }
        &--twitter {
            span {
                color: $twitter;
            }
        }

        @media (max-width: 1199px) {
            font-size: 16px;
            line-height: 20px;
        }

        @media (min-width: 1200px) {
            margin: 0 34px;

            font-size: 16px;
            line-height: 20px;
        }

        .bc-home__slide-container__slide__text__button {
            margin-top: 15px;
            button {
                margin: 0 auto;
            }
        }
    }

    h3 {
        text-align: center;
    }

    .each-fade {
        display: flex;
    }

    .each-fade .image-container {
        width: 75%;
        overflow: hidden;
    }

    .each-fade .image-container img {
        width: 100%;
    }

    .each-fade h2 {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        background: #adceed;
    }
}
