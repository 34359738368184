@import '../../../css/scss/vars';

.bc-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 999;

    @media (min-width: 1199px) {
        display: none;
    }
}

.bc-menu--visible {
    pointer-events: auto;
}

.bc-menu__container {
    background-color: #fff;
    position: relative;
    max-width: 400px;
    width: 90%;
    height: 100%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);
    -webkit-transform: translateX(103%);
    transform: translateX(103%);
    display: flex;
    flex-direction: column;
    will-change: transform;
    z-index: 160;
    pointer-events: auto;
}

.bc-menu--visible .bc-menu__container {
    -webkit-transform: none;
    transform: none;
}

.bc-menu--animatable .bc-menu__container {
    transition: all 130ms ease-in;
}

.bc-menu--visible.bc-menu--animatable .bc-menu__container {
    transition: all 330ms ease-out;
}

.bc-menu:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    opacity: 0;
    will-change: opacity;
    pointer-events: none;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
}

.bc-menu--visible.bc-menu:after {
    opacity: 1;
    pointer-events: auto;
}

/* style */

.layout {
    width: 375px;
    height: 667px;
    background-color: #f5f5f5;
    position: relative;
}

.bc-menu__container {
    width: 290px;
    height: 100%;
    margin-left: auto;
}

.bc-menu:after {
    width: 100%;
    height: 100%;
}

.bc-menu__container {
    display: flex;
    flex-direction: column;
}

.bc-menu__card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;

    border: none;
    border-bottom: 1px solid #f2f2f2;

    height: 57px;
    text-decoration: none;

    color: $darkblue;
    font-family: $f-D-DinExp;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    background-color: transparent;

    &.bc-menu__card--active {
        border-left: 2px solid $lightblue;
        font-family: $f-D-DinExp-Bold;
    }
    &.bc-menu__card--disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.bc-menu__badge {
    span {
        background-color: $lightblue;
        color: white;
        border-radius: $radius;
        right: -10px;
    }
}

.bc-menu__card-icon {
    height: 13px;
    width: 13px;

    margin-right: 10px;

    svg {
        fill: $darkblue;
    }
}

.bc-menu__card-languages {
    border: none;
    padding-top: 10px;
    padding-left: 45px;
}

.bc-menu__card-language {
    position: relative;
    width: 22px;
    height: 16px;
    padding: 0;

    border: none;
    background-color: transparent;

    &:not(:first-of-type) {
        margin-left: 10px;
    }

    svg {
        width: 22px;
        height: 16px;
    }
}
