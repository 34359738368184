@import '../../../css/scss/vars';

@mixin button-color($color) {
    border: 1px solid $color;
    color: $color;

    &:hover {
        background-color: $color;
        color: white;
    }
}

.bc-proposition-answer-card {
    background-color: #fcfbfb;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);

    @media (max-width: 575px) {
        padding: 27px 19px;
    }

    @media (min-width: 576px) and (max-width: 1199px) {
        padding: 26px 30px;
    }

    @media (min-width: 1200px) {
        padding: 27px 40px;
    }
}

.bc-proposition-answer-card__title {
    opacity: 0.5;
    color: $greyBoulder;
    font-family: $f-light;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 16px;
}

.bc-proposition-answer-card__textarea {
    margin-top: 10px;

    color: $greyBoulder;
    font-family: $f-light;
    font-size: 13px;
    line-height: 17px;
}

.bc-proposition-answer-card__answer {
    margin: 26px 12px 20px;

    color: $greyBoulder;
    font-family: $f-light;
    font-size: 13px;

    p {
        margin-bottom: 0;
    }
}

.bc-proposition-answer-card__control-buttons {
    display: flex;
    padding-top: 20px;

    text-align: right;
    justify-content: flex-end;
    align-items: center;
}

.bc-proposition-answer-card__control-title {
    margin-right: 30px;

    color: $greyBoulder;
    font-family: $f-light;
    font-size: 14px;
    letter-spacing: 1px;
}

.bc-proposition-answer-card__button {
    padding: 13px 20px;

    border: solid 1px grey;
    color: white;
    border-radius: 2px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: $f-light;
    font-size: 12px;

    &:not(:first-of-type) {
        margin-left: 10px;
    }

    &.bc-proposition-answer-card__button--yes {
        @include button-color(#2dbc5e);
    }

    &.bc-proposition-answer-card__button--discuss {
        @include button-color(#446378);
    }

    &.bc-proposition-answer-card__button--no {
        @include button-color(#ff3333);
    }

    &.bc-proposition-answer-card__button--disabled {
        cursor: not-allowed;
        border: 1px solid #d9d9d9;

        background-color: #fcfbfb;
        color: #d9d9d9;

        &:hover {
            background-color: #fcfbfb;
            color: #d9d9d9;
        }
    }

    &.bc-proposition-answer-card__button--selected {
        cursor: not-allowed;
        border: 1px solid white;

        background-color: #d9d9d9;
        color: #fcfbfb;

        &:hover {
            background-color: #d9d9d9;
            color: #fcfbfb;
        }
    }
}
