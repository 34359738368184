@import 'css/scss/_vars';

.headerBlock {
    margin: $space 0;
    padding: $smallSpace;
    text-align: center;

    .header {
        display: inline-flex;
        align-items: center;
        margin: 0;

        font-size: 14px;
        font-weight: 900;
        color: $cornBlue;
        text-transform: uppercase;

        > svg {
            width: 14px;
            margin-right: $smallSpace;
            fill: $cornBlue;
        }
    }

    .title {
        margin: $smallSpace 0;
        font-family: $f-title;
        font-weight: 700;
        font-size: 24px;
    }

    .text {
        margin: $space 0;
        font-family: $f-header;
        font-size: 16px;
    }
}

.connectButton {
    margin: $largeSpace 0 $increasedSpace;
}

.warningMsg {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.4px;
}

.pagePicker {
    p {
        margin: $space 0 $smallSpace 0;
        font-family: "Playfair Display";
        font-weight: 700;
        font-size: 24px;
        line-height: 1.25;
        text-align: center;
    }
}
