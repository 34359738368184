@import '../../../css/scss/vars';

.card {
    display: flex;
    flex-direction: column;
    &-label {
        color: rgba($darkblue, 0.6);
        font-family: $f-500;
        font-size: 11px;
        line-height: 8px;
    }
    &-box {
        height: 32px;
        line-height: 32px;
        padding: 2px 5px;
        text-align: center;
        border-radius: $radius;
        font-size: 14px;
        min-width: 60px;
        &-blue {
            background: $skyBlue;
            color: $materialBlue;
        }
        &-pink {
            background: $pink;
            color: $red;
        }
        &-red {
            color: $red;
            background: $lightRed;
        }
        &-green {
            background: $lightGreen2;
            color: $green2;
        }
    }
}
