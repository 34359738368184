@import 'css/scss/_vars';

.row {
    margin-bottom: $space;

    > .subRow {
        display: flex;
        justify-content: space-between;
        > * {
            flex: 1;
            &:not(:last-child) {
                margin-right: $space;
            }
        }
    }

    label {
        display: inline;
    }
}

.error {
    display: block;
    width: 100%;
    margin: $tinySpace 0 0 $reducedSpace;
    height: 16px;

    color: $red;
    font-size: 11px;
    text-align: left;
}
