@import '../../../css/scss/vars';

.banner {
    &__left {
        flex: 0.9;
        padding: 10px;
        color: $darkblue;
        display: flex;
        @include respond-to(mobile) {
            flex: 1;
        }
        flex-direction: column;
        &__title {
            font-family: $f-bold;
            font-weight: bold;
            font-size: 16px;
            @include respond-to(mobile) {
                font-size: 14px;
            }
            letter-spacing: 0;
            line-height: 19px;
            margin: 0;
            & > a {
                color: inherit;
                text-decoration: none;
                cursor: pointer;
                font-style: italic;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &__text {
            font-family: $f-regular;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            margin: 0;
            padding: 7px 0;
            @include respond-to(mobile) {
                font-size: 12px;
            }
        }
    }
    &__right {
        min-width: 90px;
        display: flex;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        @include respond-to(mobile) {
            min-width: 100%;
            justify-content: flex-start;
            margin-right: 0;
            margin-left: 20px;
            .bnc_field_switch {
                width: 40px;
            }
            &__label__left {
                padding-right: 5px;
            }
        }
        &__label__left {
            padding-bottom: 7px;
            padding-right: 7px;
        }
        &__label__right {
            padding-bottom: 7px;
        }
    }
}
