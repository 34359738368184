@import '../../../css/scss/vars';

.bc-quality-score {
    position: relative;
    margin: 24px 34px;
    @media (max-width: 1200px) {
        margin: 5px 30px;
    }
    .bc-quality-score__drawing {
        position: absolute;
        z-index: 2;

        @media (min-width: 1200px) {
            top: -20px;
            left: -14px;

            font-size: 24px;
        }

        @media (max-width: 1199px) {
            top: -15px;
            left: -4px;

            font-size: 14px;

            svg {
                width: 110px;
                height: 110px;
            }
        }
    }

    .bc-quality-score__inner {
        span {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            font-weight: bold;

            top: 65%;

            @media (min-width: 1200px) {
                font-size: 24px;
            }

            @media (max-width: 1199px) {
                font-size: 14px;
            }
        }
    }

    .bc-quality-score__inner__score {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        font-weight: bold;

        top: 40%;

        @media (max-width: 1199px) {
            font-size: 28px;
        }

        @media (min-width: 1200px) {
            font-size: 42px;
        }
    }

    .bc-quality-score__bg-circle {
        @media (max-width: 1199px) {
            stroke-width: 3;
        }

        @media (min-width: 1200px) {
            stroke-width: 6;
        }
    }

    .bc-quality-score__circle {
        @media (max-width: 1199px) {
            stroke-width: 3;
        }

        @media (min-width: 1200px) {
            stroke-width: 6;
        }

        &--pink {
            stroke: $pink;
        }
        &--green {
            stroke: #5de230;
        }
        &--orange {
            stroke: #f29c17;
        }
        &--red {
            stroke: #f00000;
        }
    }
}
