@import '../../../css/scss/vars';

.bc-analytics__placeholder-info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    border-left: 2px solid #00e800;

    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);

    @include respond-to(mobile) {
        padding: 14px 12px;
        margin-bottom: 15px;
    }

    @media (min-width: 768px) {
        padding: 20px;
        margin-bottom: 20px;
    }
}

.bc-analytics-placeholder-info__icon {
    margin-right: 10px;

    font-size: 0;

    svg {
        width: 21px;
        height: 21px;
        fill: #9b9b9b;
    }
}

.bc-analytics-placeholder-info__text {
    color: #9b9b9b;
    font-family: $f-light;

    @include respond-to(mobile) {
        font-size: 11px;
        text-align: center;
    }

    @media (min-width: 768px) {
        color: #9b9b9b;
    }
}
