@import '../../../../css/scss/vars';

.bc-language-dropdown {
    position: relative;
    margin-right: 13px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    width: 16px;
    height: 12px;

    svg {
        width: 16px;
        height: 12px;
    }
}

.bc-language-dropdown-menu {
    display: block;
    opacity: 0;
    pointer-events: none;

    position: absolute;
    top: 4px;
    right: -11px;
    transform: translate3d(0, 12px, 0);
    z-index: 1000;

    padding-top: 5px; // space for the arrow
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);
    border-radius: 4px;

    transition: opacity 0.1s ease-out;

    &--visible {
        pointer-events: auto;
        opacity: 1;
    }
}

.bc-language-dropdown-menu__arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 5px;

    top: 0;
    right: calc(50% - 5px);

    &::before {
        position: absolute;
        content: '';
        border-color: transparent;
        border-style: solid;

        bottom: 0;
        border-width: 0 calc(10px / 2) 5px;
        border-bottom-color: white;
    }
}

.bc-language-dropdown-menu__item {
    display: flex;
    align-items: center;

    border: none;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;

    padding: 4px 8px;

    &:focus {
        z-index: 1;
    }
}

.bc-language-dropdown-menu__item-picto {
    width: 22px;
    height: 16px;

    svg {
        width: 22px;
        height: 16px;
    }
}
