@import 'css/scss/_vars';

.card {
    margin: $smallSpace 0;
    padding: 24px;
    border-radius: $avgRadius;

    &.active {
        background-color: $pureWhite;
        box-shadow: $altBoxshadow;
    }
    &.inactive {
        opacity: 0.3;
    }
    &.finished {
        color: $cornBlue;
        background-color: #f8f9fa;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.question {
    font-size: 16px;
    line-height: 20px;

    @media only screen and (min-width: $break-tablet) {
        flex: 0.63;
    }
}

.check {
    margin-left: $space;
    svg {
        fill: $green;
    }
}

.buttons {
    display: flex;
    margin-top: $space;

    > button {
        font-size: 14px !important;

        &:first-child {
            margin-right: $space;
        }
    }
}
