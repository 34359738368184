@import 'css/scss/_vars2.scss';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: $radius;
}

.toolbar {
  padding: 0;
  display: flex;
  align-items: center;
  border: none;
  order: 2;
  margin-bottom: 0;
  > div {
    margin-bottom: 0;
  }
}

.editor {
  color: $kolnavy;
  cursor: text;
  border-radius: $radius;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-bottom: $smallSpace;
  height: 120px;
  order: 1;
  position: relative;
  word-break: break-word;
  a {
    color: $kolblue;
  }

}

.button {
  height: 25px;
  background: none;
  border: none;
  color: $kolnavy;
  border-radius: 100%;
  margin: 0;
  transition: background 0.2s linear;
  box-sizing: initial;

  &:hover {
    background: rgba(10,10,10,0.1);
    box-shadow: none;
  }

  img {
    width: 15px;
    height: 15px;
    filter: invert(13%) sepia(36%) saturate(3724%) hue-rotate(187deg) brightness(95%) contrast(101%);
  }
}

.filePicker {
  display: flex;
  align-items: center;
  margin-bottom: $smallSpace;

  &:focus {
    outline: none;
  }
}

.emojiPicker {
  position: absolute;
  left: $bigSpace;
  bottom: 0;
  z-index: 2;
}

.saveButton {
  display: flex;
  flex: auto;
  justify-content: flex-end;
}
