@import '../../../css/scss/vars';

.bc-profile-card {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);
    border-radius: 4px;

    @include respond-to(mobile) {
        background-color: white;
    }
}

.bc-profile-card__body {
    min-height: 100px;

    @include respond-to(mobile) {
        display: flex;
        align-items: center;
    }
}

.bc-profile-card__mobile-edit {
    position: relative;
    width: 14px;
    height: 14px;
    margin: -7px;
    margin-left: auto;
    top: 15px;
    right: 15px;

    @media (min-width: 768px) {
        display: none;
    }

    svg {
        fill: $darkblue;
    }
}

.bc-profile-card__background-img {
    background: #d8d8d8 no-repeat top;
    background-size: cover;
    background-attachment: inherit;
    border-radius: 4px 4px 0 0;
    opacity: 0.5;

    @media (min-width: 768px) {
        height: 90px;
    }
}

.bc-profile-card__img-profile {
    background: white no-repeat center;
    background-attachment: inherit;

    &.bc-profile-card__img-profile--empty {
        background-image: url('../../../assets/images/defaultUser.png');

        @include respond-to(mobile) {
            background-size: 30px;
            background-color: #f4f4f4;
        }

        @media (min-width: 768px) {
            background-size: 45px;
        }
    }

    @include respond-to(mobile) {
        width: 50px;
        height: 50px;
        background-size: 50px;

        border-radius: 25px;
        margin-left: 12px;
        margin-right: 12px;
    }

    @media (min-width: 768px) {
        position: relative;
        top: -60px;
        margin: auto auto -80px;

        width: 80px;
        height: 80px;
        background-size: 80px;

        z-index: 4;
        overflow: hidden;

        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.18);
    }

    img {
        overflow: hidden;
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
}

.bc-profile-card__infos {
    background-color: white;

    @include respond-to(mobile) {
        margin-right: 12px;
    }

    @media (min-width: 768px) {
        min-height: 110px;
        padding: 30px 15px 13px;
        border-radius: 0 0 4px 4px;
    }

    h3 {
        font-family: $f-regular;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 2px;
    }
}

.bc-profile-card__infos-activities {
    font-family: $f-light;
    font-size: 13px;
    line-height: 15px;

    @media (min-width: 768px) {
        margin-top: 10px;
    }
}

.bc-profile-card__infos-tags {
    margin-top: 10px;

    @include respond-to(mobile) {
        display: none;
    }
}

.bc-profile-card__edit {
    text-align: right;

    a {
        color: $darkblue;
        font-family: $f-300;
        font-size: 11px;
        line-height: 13px;
        text-decoration: underline;
    }
}
