@import '../../../../css/scss/vars';

.bc-home-slide__placeholder {
    @include respond-to(mobile) {
        margin: 0 20px 20px;
    }

    .bc-home-slide__placeholder__content {
        display: grid;
        padding: 34px 0;

        @media (max-width: 1199px) {
            grid-template-areas:
                'title'
                'text'
                'button';
            justify-items: center;
        }

        @media (min-width: 1200px) {
            grid-template-areas:
                'title'
                'text'
                'button';
        }
    }

    .bc-home-slide__placeholder__content__title {
        grid-area: title;
        align-self: flex-end;
        text-align: center;
        margin-bottom: 18px;

        @media (max-width: 1199px) {
            font-size: 24px;
            line-height: 35px;
        }

        @media (min-width: 1200px) {
            font-size: 31px;
            font-weight: normal;
        }
    }

    .bc-home-slide__placeholder__content__text {
        grid-area: text;
        justify-self: center;
        background-size: cover;
        flex-direction: column;
        text-align: center;

        font-size: 16px;
        line-height: 20px;

        @media (max-width: 1199px) {
            margin: 8px;
        }

        @media (min-width: 1200px) {
            margin: 8px 34px;
        }
    }

    .bc-home-slide__placeholder__content__button {
        grid-area: button;
        align-self: flex-end;
        text-align: center;

        @media (max-width: 1199px) {
            margin: 8px;
        }

        @media (min-width: 1200px) {
            margin: 8px;
        }

        button {
            // Reset button default style
            appearance: none;
            display: block;
            padding: 0;
            margin: 0 auto;
            width: initial;

            border: none;
            border-radius: 4px;
            cursor: pointer;
            background-color: $instagram;
            font-family: $f-regular;
            font-size: 13px;
            color: #ffffff;
            padding: 8px 15px;

            &.btn-instagram--small {
                width: auto;
                height: auto;
                padding: 8px 20px 8px 15px;
            }

            /**
       * Fix a bug on iPad/mobile
       * On these devices `display`, `align-items`, and `justify-content` don't work well with <button>
       * Something is breaking them somewhere else in the CSS and even with !important this doesn't work. Because <div>
       * works fine with `display` and others, we wrap our content with it to fix the bug.
       **/
            .bc-home-slide__placeholder__content__button__wrapper {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    fill: #fff;
                    width: 22px;
                    height: 22px;
                    margin-right: 15px;
                }

                div {
                    display: block;
                    text-align: left;
                }
            }

            .bc-home-slide__placeholder__content__button__wrapper__label {
                color: #ffffff;
                font-family: $f-medium;
                font-size: 14px;
                line-height: 20px;
            }

            .bc-home-slide__placeholder__content__button__wrapper__subtext {
                margin-top: 2px;
                font-family: $f-light;
            }
        }
    }
}
