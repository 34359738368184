@import 'css/scss/_vars';

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: $space;

    > * {
        flex: 1;
        &:not(:last-child) {
            margin-right: $space;
        }
    }

    label {
        display: inline;
    }
}

.labels {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;

    color: $darkblue;

    margin-bottom: $smallSpace;
}
