@import '../../../css/scss/vars';

.layout {
    max-width: 1000px;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    padding-bottom: 60px;
    border-radius: $radius;

    @include respond-to(mobile) {
        margin: 25px auto 0;
    }
    @media (min-width: 768px) {
        margin: 42px auto 83px;
    }

    a.grey {
        color: $grey;
        font-size: 13px;
    }

    * ~ .container {
        margin-left: $largemargin;
    }

    .container {
        display: flex;
        flex-direction: column;
        flex: 1;

        background-color: white;
        box-shadow: $boxshadow;
        border-radius: $radius;
        padding: $space;

        .logo {
            margin: $space auto;
            img {
                width: 105px;
                height: auto;
            }
        }

        h2 {
            font-family: $f-300;
            font-size: 30px;
            line-height: 35px;
            text-align: center;
        }

        h3 {
            font-size: 18px;
            line-height: 21px;
            text-align: center;
        }

        section {
            width: 360px;
            margin: $space auto;

            > div:not(:last-of-type) {
                margin-bottom: $space;
                &.mb10 {
                    margin-bottom: $mediummargin;
                }
            }
        }

        > hr {
            margin: $smallSpace $largeSpace;
            background-color: $divider;
        }

        .bnc_field_input label > span.label {
            font-weight: normal;
            font-size: 13px;
        }

        /* TAGS UPDATES */
        .bnc_field_select_tags_container .bnc_tag {
            font-weight: normal;
            font-family: $f-bold;
        }
    }
}

@include respond-to(tablet-portrait) {
    .layout {
        width: 100%;
        flex-direction: column-reverse;
        align-items: flex-end;
    }
}

@include respond-to(mobile) {
    .layout {
        margin-top: 0;
        flex-direction: column-reverse;
        flex-flow: wrap-reverse;
        .container {
            margin: $largemargin;
            section {
                width: 100%;
            }
        }
    }
}
