@import 'css/scss/_vars2.scss';

.wrapper {
  position: relative;
  display: flex;
  justify-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: $space;

  .content {
    display: flex;
    margin-top: $space;

    .message {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      max-width: 75%;
      margin: 0 12px;
    }

    .bubble {
      color: $kolnavy;
      padding: $space;
      background-color: #F8F9FA;
      border-radius: $bigSpace;
      overflow: hidden;

      .author {
        font-weight: 600;
        margin-bottom: $tinySpace;
      }

      .bubbleMessage {
        font-size: 14px;
        overflow-wrap: break-word;
        word-wrap: break-word;

        p {
          word-break: break-word;
          color: inherit;
          font-size: 14px;
          margin-bottom: $tinySpace;
          line-height: 21px;
        }
        a {
          color: $kolblue;
          &:hover {
            text-decoration: underline;
          }
        }
        ul, ol {
          padding-left: $space;
          margin: $tinySpace 0;
          white-space: nowrap;
        }
      }

      p, blockquote, ul {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      overflow: hidden;
      background-color: rgba($kolpink, 0.4);
      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  &.grouped {
    padding-top: 0;
    .content {
      margin-top: 0;
      .avatar {
        opacity: 0;
      }
    }
  }

  &.me {
    .content {
      flex-direction: row-reverse;
    }

    .message {
      display: flex;
      justify-content: flex-end;
    }

    .bubble {
      background-color: #CCFFFA;;
    
      .bubbleMessage {
        color: $kolnavy;
      }
    }
  }

  .attachments {
    width: 100%;
    margin-top: $smallSpace;
  }
}

.timestamp {
  width: 100%;
  color: $kolnavy;
  font-size: 12px;
  text-align: center;
}

