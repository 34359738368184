@import '../../css/scss/vars';

.bc-proposition-show {
    @include respond-to(mobile) {
        margin: 20px 14px 100px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        margin: 20px 14px 40px;
    }

    @media (min-width: 1200px) {
        margin: 42px auto;
        max-width: 1000px;
    }

    .bc-proposotion-show__link-back {
        color: #9b9b9b;
        font-family: $f-light;
        font-size: 13px;
        line-height: 15px;

        @media (max-width: 575px) {
            text-decoration: none;
            display: flex;

            font-size: 12px;
            line-height: 14px;

            div {
                padding: 11px 10px;

                background-color: white;
                border: 1px solid #d8d8d8;
                border-radius: 2px;
            }
        }
    }

    .bc-proposition-show-container {
        margin-top: 11px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .bc-proposition-details-card-container {
        @media (max-width: 575px) {
            width: 100%;
            margin-top: 10px;
        }
    }

    .bc-proposition-show__section-left {
        @media (min-width: 576px) and (max-width: 1199px) {
            width: calc(100% - 10px - 235px);
            margin-right: 10px;
            margin-top: 10px;
        }

        @media (min-width: 1200px) {
            width: calc(100% - 20px - 235px);
            margin-right: 20px;
        }
    }

    .bc-proposition-show__section-right {
        width: 235px;

        @media (min-width: 576px) and (max-width: 1199px) {
            margin-top: 10px;
        }
    }

    .bc-proposition-description-card-container {
        width: 100%;

        @media (max-width: 575px) {
            margin-top: 10px;
        }

        @media (min-width: 1200px) {
            margin-top: 20px;
        }
    }

    .bc-proposition-answer-card-container {
        border-top: 2px solid #dddddd;
    }

    .bc-contact-card-container {
        width: 100%;

        @media (max-width: 1199px) {
            margin-top: 10px;
        }

        @media (min-width: 1200px) {
            margin-top: 30px;
        }
    }
}

.bc-proposition-answer-mobile-container {
    width: 100%;
}

.bc-proposition-answered-mobile-container {
    margin-top: 10px;
    width: 100%;
}
