@import 'css/scss/_vars2.scss';

.list {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-flow: column wrap;
  width: 50%;
  height: 100%;
  overflow: hidden;

  @media only screen and (min-width: $l) {
    width: 33%;
    max-width: 420px;
    border-right: $border;
  }

  .itemWrapper {
    display: flex;
    position: absolute;
    flex-flow: column wrap;
    width: 100%;
    height: 100%;
    background-color: $white;
    will-change: transform;
    z-index: 1;
  }
}
