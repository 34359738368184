@import 'css/scss/_vars.scss';

.container {
    color: $darkblue;

    h3 {
        margin-bottom: $largemargin;
        font-size: 22px;
        text-align: center;

        strong {
            font-family: $f-regular;
        }
    }
    p {
        font-family: $f-regular;
        text-align: center;
        margin: $mediummargin 0;
        line-height: 21px;
    }
}
