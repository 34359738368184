@import '../../../css/scss/vars';

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap; // allow us to do the line break for collapsing content
    align-items: center;
    justify-content: space-between; // space out brand from logo
}

.navbar-brand {
    display: inline-block;
    line-height: inherit;
    white-space: nowrap;
    position: relative;

    img {
        @include respond-to(mobile) {
            height: 22px;
        }

        @media (min-width: 768px) {
            height: 30px;
        }
    }
}

.nav-item {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 150px;
}

.navbar-nav {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .nav-link {
        font-family: $f-D-DinExp;
        text-decoration: none;
        color: inherit;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 0.2s ease-out;

        &.active {
            font-family: $f-D-DinExp-Bold;
            border-bottom: 2px solid $pink;
        }
        &:hover {
            background-color: rgba($darkblue, 0.1);
        }
        &.disabled {
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
        }
    }

    .dropdown-menu {
        position: static;
        float: none;
    }
}

.navbar-badge {
    span {
        background-color: $pink;
        color: white;
        border-radius: $radius;
        @include respond-to(desktop) {
            right: -10px;
        }
    }
}

.navbar-collapse {
    height: 100%;
    @media (max-width: 1199px) {
        display: none;
    }

    @media (min-width: 1200px) {
        display: flex;
    }
}

.navbar-text {
    display: inline-block;
}

.navbar-mobile {
    display: flex;

    @media (min-width: 1200px) {
        display: none;
    }
}

.navbar-toggler {
    cursor: pointer;

    @media (max-width: 1199px) {
        border: none;
        background-color: transparent;
    }

    @media (min-width: 1200px) {
        display: none;
    }

    &:focus {
        outline: none;
    }
}

.navbar-toggler-line {
    height: 2px;
    background-color: $darkblue;
    width: 23px;

    &:not(:first-of-type) {
        margin-top: 6px;
    }
}
