@import '../../../css/scss/vars';

.banner {
    padding: $space;
    background: #003057;
    text-align: center;
    .icon {
        display: inline-block;
        transform: translateY(-2px);
    }
    .description {
        font-weight: 500;
        color: white;
    }
    .send {
        cursor: pointer;
        border: none;
        display: inline-block;
        padding: 0;
        color: $pink;
        font-size: 14px;
        font-weight: 500;
        background-color: transparent;
        &:active, &:focus {
            box-shadow: none;
        }
    }
}
