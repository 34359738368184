@import 'css/scss/_vars2.scss';

.container {
  padding: $space;
  border-bottom: $border;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin-top: -4px !important;
    }
  }

  .input {
    margin-top: $space;
  }
}
