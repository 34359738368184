@import '../../../css/scss/vars';

:export {
    arrowGreen: $green3;
    arrowRed: $red;
}

@mixin textColors {
    &.positive {
        color: $green3;
    }
    &.negative {
        color: $red;
    }
    &.equal {
        color: $materialBlue;
    }
}
@mixin cardColors {
    &.blue {
        background: $skyBlue;
    }
    &.pink {
        background: $pink;
    }
    &.red {
        background: $lightRed;
    }
    &.green {
        background: $lightGreen2;
    }
}

.evolutionCard {
    height: 20px;
    font-size: 10px;
    display: inline-flex;
    align-items: center;
    padding: 0 5px;
    border-radius: 2px;

    margin: $mediummargin auto;

    > span {
        text-align: center;
    }

    .equalsign {
        font-size: 15px;
        width: 15px;
    }

    @include textColors;
    @include cardColors;
}
