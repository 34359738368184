@import '../../../css/scss/vars';

.bc-proposition-header-card {
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);
    width: 100%;

    @include respond-to(mobile) {
        padding: 12px 6px;
    }

    @media (min-width: 768px) {
        padding: 19px 15px 12px;
    }
}

.bc-proposition-header-card__status {
    text-align: right;

    //color: #F5A623;
    font-family: $f-light;

    @include respond-to(mobile) {
        font-size: 10px;
        line-height: 11px;
    }

    @media (min-width: 768px) {
        font-size: 12px;
        line-height: 14px;
    }

    &:after {
        content: ' \25CF';
        font-size: 16px;
        position: relative;
        top: 1px;
    }
}

.bc-proposition-header-card__status--red {
    color: #b32a29;
}
.bc-proposition-header-card__status--green {
    color: #7ed321;
}
.bc-proposition-header-card__status--yellow {
    color: #ffa500;
}
.bc-proposition-header-card__status--blue {
    color: #0b2c42;
}

.bc-proposition-header-card__body {
    display: flex;

    @include respond-to(mobile) {
        flex-direction: column;
        justify-items: center;
        text-align: center;

        padding: 10px 10px 0;
    }

    @media (min-width: 768px) {
        padding: 10px 150px 10px 10px;
    }
}

.bc-proposition-header-card__body--answered {
    @media (min-width: 768px) {
        padding: 10px;
    }
}

.bc-proposition-header-body__picto {
    color: #d8d8d8;

    @include respond-to(mobile) {
        order: 2;
        margin: auto;

        svg {
            height: 19px;
            width: 19px;
        }
    }

    @media (min-width: 768px) {
        margin-right: 24px;

        svg {
            height: 46px;
            width: 47px;
        }
    }
}

.bc-proposition-header-body__content {
    @include respond-to(mobile) {
        order: 1;
    }
}

.bc-proposition-header-content__title {
    color: #4a4a4a;

    @include respond-to(mobile) {
        font-size: 18px;
        line-height: 21px;
        font-family: $f-regular;
    }

    @media (min-width: 768px) {
        font-size: 22px;
        line-height: 25px;
        font-family: $f-light;
    }
}

.bc-proposition-header-content__brand {
    margin-top: 4px;

    color: #9b9b9b;
    text-transform: uppercase;
    font-family: $f-light;
    font-size: 15px;
    line-height: 23px;
}

.bc-proposition-header__answer {
    margin-top: -30px;
    display: flex;
    justify-content: flex-end;

    a {
        display: block;
        padding: 7px 20px;
        border: 1px solid #1f2f39;
        border-radius: 2px;
        background-color: #1f2f39;

        color: #ffffff;
        font-family: $f-light;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        text-decoration: none;
    }

    @include respond-to(mobile) {
        display: none;
    }
}
