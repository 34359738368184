@import '../../../../css/scss/vars';

.bc-analytics-post {
    position: relative;

    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(10, 31, 68, 0.1);
}

.bc-analytics-post-link {
    color: black;
    text-decoration: none;

    @include respond-to(mobile) {
        margin-top: 15px;
        width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        margin-top: 10px;
        margin-left: 5px;
        margin-right: 5px;
        width: calc((100% - 2 * 3 * 5px) / 3);
    }

    @media (min-width: 1200px) {
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
        width: calc((100% - 2 * 3 * 10px) / 3);
    }
}

.bc-analytics-post__image {
    position: relative;
    text-align: center;

    font-size: 0; // Remove useless space when the picture can't be found
    .bc-analytics-post__icon-type {
        position: absolute !important;
        top: $space;
        right: $space;
    }
}


.bc-analytics-post__image-content {
    max-width: 100%;
    max-height: 400px;

    background-color: white;
}

.bc-analytics-post__content {
    color: #4a4a4a;
    font-family: $f-light;
    font-size: 12px;
}

.bc-analytics-post__top {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 16px 10px 10px;

    color: #4a4a4a;
    font-size: 13px;

    svg {
        width: 14px;
        height: 14px;
    }
}

.bc-analytics-post__social-network-shell {
    position: relative;
}

.bc-analytics-post-social-network-tooltip {
    display: none;
    position: absolute;
    top: -2px;
    right: -10px;
    transform: translate3d(0, -38px, 0);
    z-index: 1000;

    //padding-bottom: 5px; // space for the arrow
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);
    border-radius: 4px;

    &--deployed {
        display: block;
    }
}

.bc-analytics-post-social-network-tooltip__arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 5px;

    bottom: -5px;
    right: calc(35px / 2) - 5px + 10px - 10px;

    &::before {
        position: absolute;
        content: '';
        border-color: transparent;
        border-style: solid;

        top: 0;
        border-width: 5px calc(10px / 2) 0;
        border-top-color: white;
    }
}
.bc-analytics-post-social-network-tooltip__content {
    padding: 10px;
    white-space: nowrap;

    font-family: $f-light;
    font-size: 13px;
    color: #4a4a4a;
}

.bc-analytics-post__hour {
    font-style: italic;
}

.bc-analytics-post__text {
    margin: 0;
}

.bc-analytics-post__stats {
    display: flex;
    flex-direction: column;
    padding: 10px 12px;

    color: $darkblue;
    font-family: $f-regular;
    background-color: rgba(0, 48, 87, 0.03);
}

.bc-analytics-post-stats__engagement {
    display: flex;
    align-items: center;
    margin-bottom: $mediummargin;
}

.bc-analytics-post-stats__engagement-label {
    font-size: 9px;
    margin-right: $smallmargin;
}

.bc-analytics-post-stats__engagement-rate {
    display: flex;
    align-items: center;
    .bc-analytics-post-stats__engagement-rate-value {
        font-size: 14px;
        margin-right: $smallmargin;
    }

    .bc-analytics-post-stats__engagement-rate-percentage {
        font-size: 9px;
    }
}

.bc-analytics-post-stats__details {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-gap: $smallmargin;
}

.tooltip-small {
    text-align: left;
    font-size: 12px;
}

.bc-analytics-post-stats__detail {
    text-align: center;
    font-size: 10px;
    opacity: 0.6;

    svg {
        height: 11px;
        width: 11px;
        fill: $darkblue;
    }
}

.bc-analytics-post-stats__detail-icon-top-post {
    width: unset !important;
    height: unset !important;
    svg {
        width: 12px !important;
        height: 12px !important;
    }
}

.bc-analytics-post-stats__detail-metric {
    margin-top: 4px;
    margin-bottom: 2px;

    font-family: $f-bold;
}

.bc-analytics-post-stats__detail-icon {
    width: 11px !important;
    height: 11px !important;
    svg {
        width: 11px !important;
        height: 11px !important;
    }
    margin: 0 auto 6px auto;
}

.bc-analytics-post-stats__detail-label {
    font-size: 8px;
}
