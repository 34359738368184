@import '../../../css/scss/vars';

.bc-mascarade {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 39px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 640px;
    padding: 14px 40px 14px 48px;

    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.1), 0 16px 16px 0 rgba(10, 31, 68, 0.15);
    border-radius: 4px;
    background-color: #003057;
    color: #fff;
    z-index: 999;

    svg {
        height: 20px;
        width: 20px;
        margin-right: 12px;

        fill: #ff729f;
    }
}
