@import 'css/scss/_vars';

.background {
    min-height: 100vh;
    background-color: $pureWhite;
    overflow: auto;
    padding-left: calc(100vw - 100%); // prevent scrollbar jumping on "see more/less"

    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.3;
        background: url('/assets/images/patterns/onboarding.svg');
        background-size: cover;
        content: '';
    }
    @media only screen and (min-width: $break-tablet) {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(0deg, #5fccff, #5fccff),
            linear-gradient(72.39deg, #dcccff -101.33%, #5fccff 52.14%, #00ccff 168.8%);
    }

    @media only screen and (min-width: $break-xtraLarge) {
        display: flex;
    }
}

.logo {
    display: flex;

    .imgPlaceholder {
        width: 140px;
        height: 38px;
        margin: 20px auto;
        background: url('/assets/images/logo_kolsquare.svg') no-repeat;
    }

    @media only screen and (min-width: $break-tablet) {
        position: absolute;
        top: 0;

        .imgPlaceholder {
            margin: 40px auto;
            background: url('/assets/images/logo_kolsquare_white.svg') no-repeat;
        }
    }

    @include respond-to(13inches) {
        display: none;
    }

    @media only screen and (min-width: $break-xtraLarge) {
        display: flex;
    }
}

.stepper {
    > div:last-child {
        padding: $increasedSpace $increasedSpace $largeSpace;
    }

    @media only screen and (min-width: $break-tablet) {
        max-width: 620px;
        margin: $space auto;
        > div:last-child {
            padding: $increasedSpace $largeSpace $largeSpace;
        }
    }
}
.contentWrapper {
    padding: $tinySpace 0;
    @media only screen and (min-width: $break-tablet) {
        padding: $increasedSpace;
    }
}
