$darkblue: #003057;
$darkerblue: #4c6e89;
$lightblue: #00ccff;
$lighterBlue: #33d6ff;
$materialBlue: #266b9a;
$cornBlue: #638198;
$skyBlue: #bcecf9;
$black: #4a4a4a;
$disabled: #edf1f7;
$darkgrey: #54585a;
$metalblue: #7f97b0;
$pink: #ff729f;
$red: #da2727;
$lightRed: rgba(251, 33, 59, 0.15);
$lightGreen: #acfcd6;
$lightGreen2: #d5fde5;
$green: #30e2bf;
$green2: #25b397;
$green3: #17a2a1;
$green4: #24c2b2;
$green5: #eafef2;
$grey: #9b9b9b;
$greyBoulder: #777474;
$greyAthens: #f8f9fa;
$lightGrey: #f2f4f6;
$pureWhite: #fff;
$errorLabel: #DD2727;
$successLabel: #43a047;

$divider: rgba(#ccd6dd, 0.7);

$boxshadow: 0 0 4px 0 rgba($darkblue, 0.1);
$altBoxshadow: 0 4px 12px rgba($darkblue, 0.1);
$border: 1px solid rgba($darkblue, 0.2);

$facebook: #37559b;
$instagram: #bc34af;
$instagramIgtv: #ff3325;
$youtube: #ff0000;
$twitter: #008ffd;
$tiktok: #101010;
$snapchat: #fffc00;
$pinterest: #d32f2f;
$twentyone_buttons: #000000;
$twitch: #8e24aa;

$subTitle: rgba($darkblue, 0.6);

$f-thin: 'Roboto-Thin';
$f-100: 'Roboto-Thin';
$f-light: 'Roboto-Light';
$f-300: 'Roboto-Light';
$f-regular: 'Roboto';
$f-400: 'Roboto';
$f-medium: 'Roboto-Medium';
$f-500: 'Roboto-Medium';
$f-bold: 'Roboto-Bold';
$f-700: 'Roboto-Bold';

$f-header: 'D-DINExp';
$f-title: 'Playfair Display';

$f-D-DinExp: 'D-DINExp';
$f-D-DinExp-Bold: 'D-DINExp-Bold';
$f-D-DinExp-Italic: 'D-DINExp-Italic';

$break-mobile: 767px;
$break-tablet: 768px;
$break-desktop: 1024px;
$break-large: 1280px;
$break-xtraLarge: 1440px;

$pageContentMaxWidth: 830px;

@mixin respond-to($media) {
    @if $media == 13inches {
        @media only screen and (min-width: $break-large) and (max-width: $break-xtraLarge) {
            @content;
        }
    } @else if $media == desktop {
        @media only screen and (min-width: $break-desktop) {
            @content;
        }
    } @else if $media == tablet-landscape {
        @media only screen and (max-width: $break-desktop - 1) {
            @content;
        }
    } @else if $media == tablet-portrait {
        @media only screen and (max-width: $break-tablet) {
            @content;
        }
    } @else if $media == mobile {
        @media only screen and (max-width: $break-mobile) {
            @content;
        }
    }
}

$smallmargin: 5px;
$mediummargin: 10px;
$largemargin: 20px;

$tinySpace: 4px;
$smallSpace: 8px;
$reducedSpace: 12px;
$space: 16px;
$increasedSpace: 24px;
$largeSpace: 32px;
$hugeSpace: 64px;

$radius: 4px;
$avgRadius: 8px;
$bigRadius: 12px;

$navHeight: 60px;

:export {
    darkblue: $darkblue;
    darkerblue: $darkerblue;
    lightblue: $lightblue;
    lighterBlue: $lighterBlue;
    skyBlue: $skyBlue;
    black: $black;
    disabled: $disabled;
    pink: $pink;
    lightGrey: $lightGrey;
    lightGreen: $lightGreen;
    lightGreen2: $lightGreen2;
    green: $green;
    green2: $green2;
    green3: $green3;
    green4: $green4;
    materialBlue: $materialBlue;
    red: $red;
    lightRed: $lightRed;
    facebook: $facebook;
    twitter: $twitter;
    instagram: $instagram;
    instagramIgtv: $instagramIgtv;
    tiktok: $tiktok;
    snapchat: $snapchat;
    pinterest: $pinterest;
    twentyone_buttons: $twentyone_buttons;
    twitch: $twitch;
}

@mixin boxTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
}

@mixin pageContent {
    max-width: $pageContentMaxWidth;
    overflow-anchor: none;

    @include respond-to(mobile) {
        margin: 0 $space 90px;
    }

    @media (min-width: 768px) and (max-width: 990px) {
        margin: $space $space $hugeSpace;
    }

    @media (min-width: 991px) {
        margin: $largeSpace auto $hugeSpace;
    }
}

@mixin paper {
    display: inline-block;
    border-radius: $radius;
    box-shadow: $boxshadow;
    background-color: $pureWhite;
}

@mixin title {
    text-align: center;

    @include respond-to(mobile) {
        font-size: 20px;
        line-height: 25px;
    }

    @media (min-width: 768px) {
        font-size: 30px;
        line-height: 35px;
    }
}

@mixin pageMargin {
    margin: $space auto;

    @media (min-width: 768px) and (max-width: 1199px) {
        margin: $space auto;
    }

    @media (min-width: 1200px) {
        margin: $largeSpace auto;
    }
}
