@import '../../../css/scss/vars';

.bc-dialog {
    .MuiBackdrop-root {
        background-color: $darkblue;
    }
    .bc-dialog__close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .bc-dialog__title {
        h2 {
            color: $darkblue;
            font-weight: bold;
            font-size: 22px;
            margin-right: 50px;
        }
    }
}
