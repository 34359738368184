@import '../css/scss/vars';

/* Toast */

.Toastify__toast-container {
    @media (min-width: $break-tablet) {
        width: 650px;
    }
}

.Toastify__progress-bar {
    background: none;
    background-color: rgba(255, 255, 255, 0.4);
}

.toast {
    background-color: #0b2c42;
    width: 100%;
    padding: 0;
    padding-left: 24px;
    color: #ffffff;
    font-family: $f-medium;
    font-size: 13px;
    min-height: 48px;
    font-weight: 300;
    span {
        font-weight: bold;
        white-space: nowrap;
    }
    button {
        color: #798b97;
        svg {
            font-size: 16px;
        }
    }
}

/* react-dates */
.DateInput_input__focused {
    border-bottom-color: $pink;
}

.SingleDatePickerInput__withBorder {
    border: 1px solid #ccd6e3;
    border-radius: $radius;
    overflow: hidden;
}

.DateInput_input {
    color: $darkblue;
    font-size: 14px;
    font-weight: 400;
}
