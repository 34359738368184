@import '../../../../css/scss/vars';

.bc-analytics-pagination {
    display: flex;
    flex-direction: row;
}

.bc-analytics-pagination__next {
    height: 36px;
    width: 36px;
    cursor: pointer;
    align-self: center;

    border: 0.5px solid #dddddd;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 rgba(221, 221, 221, 0.5);

    &:first-of-type {
        margin-right: 5px;
    }

    &:last-of-type {
        margin-left: 5px;
    }

    svg {
        position: relative;
        top: 2px;
        right: 2px;
        width: 17px;
        height: 17px;
    }

    &-forward {
        transform: rotate(180deg);

        svg {
            position: relative;
            bottom: 2px;
            right: 2px;
            top: 0;
        }
    }
}

.bc-analytics-pagination__page {
    height: 36px;
    width: 36px;
    cursor: pointer;
    border: none;

    color: #9b9b9b;
    font-family: $f-light;
    border: 0.5px solid #dddddd;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 rgba(221, 221, 221, 0.5);

    &:first-of-type {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }

    &:last-of-type {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    &:focus {
        z-index: 1;
    }

    &--selected {
        color: white;
        background-color: $lightblue;
        border-color: $lightblue;
    }
}
