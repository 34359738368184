@import 'css/scss/_vars2.scss';

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 0 !important;

  @media only screen and (min-width: $m) {
    width: 380px;
    margin: 0 auto;
  }
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px !important;
  border-bottom: $border;
  overflow: hidden;

  .mobile {
    display: flex;

    @media only screen and (min-width: $l) {
      display: none;
    }
  }

  .info {
    flex-grow: 2;
    padding: 0 $smallSpace;
    overflow: hidden;

    .contactItem {
      display: flex;
      flex-flow: row wrap;

      > div {
        @include textShorten;
        overflow: hidden;
      }
    }

    .lastMessage {
      margin-top: $tinySpace;
      font-size: 11px;
      color: rgba($kolnavy, 0.4);

      @media only screen and (min-width: $l) {
        font-size: 13px;
      }
    }
  }
  .fullName {
    padding-left: $tinySpace;
    font-weight: 400;
  }
  .tag {
    max-width: 50%;
  }
}

.participants {
  display: flex;
  max-width: 33%;
}

.footerContainer {
  padding: $space;
  width: 100%;

  .chatFooter {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: $bigRadius;
    min-height: 49px;
    background-color: $white;
    border: 1px solid #F2F4F6;
    cursor: pointer;
    position: relative;
    outline: none;

    &.droppableWrapperActive {
      &::after {
        box-sizing: border-box;
        border: 4px dashed $kolblue;
        border-radius: $bigRadius;
        position: absolute;
        content: '';
        width: 100%;
        top: 0;
        bottom: 0;
        background-color: rgba(black, .1);
      }
    }

    &.active {
      cursor: default;
      @include borderFocus;
    }

    &.readOnly {
      flex-direction: row;
      grid-gap: $space;
      padding: $space;
      background-color: $darkgrey;
      cursor: not-allowed;

      * {
        color: $greyLynch;
      }

      ul {
        padding-left: $space;
      }
    }

    .inputPlaceholder {
      width: 100%;
      font-weight: 400;
      color: #7F97B0;
      font-size: 14px;
      padding: 0 24px;
    }

    .wrapperForm {
      padding: $space;
      width: 100%;

      .subject {
        input::placeholder {
          font-size: 14px;
        }
      }
    }

    .row {
      width: 100%;
      margin-top: 0;
      position: relative;
      @media only screen and (min-width: $m) {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
      }

      &.template {
        display: none;

        @media only screen and (min-width: $l) {
          display: grid;
        }
      }

      .grow {
        flex-grow: 2;

        > div {
          bottom: 0;
        }
      }

      .icon {
        margin: 0 $smallSpace 0 0;
      }

      @media only screen and (min-width: $l) {
        .icon {
          margin: 0 $smallSpace 0 $smallSpace;
        }
      }

      @media only screen and (min-width: $xl) {
        display: grid;
      }
    }

    .attachmentsWrapper {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    .sendButton {
      margin-left: auto;
      @media only screen and (min-width: $m) {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}

.sending {
  padding: 0;
  min-width: 200px;
  & > div {
    padding: 0;
  }
}

.selectSubject {
  position: sticky;
  display: flex;
  justify-content: center;
  width: 98%; // reduce to fix scrollBar
  margin: auto;
  background-color: $white;
  top: 0;
  box-shadow: 0 -2px 5px 0px $white;

  &.isNotFirst {
    margin-top: $bigSpace - $tinySpace;
  }
}