
$borderRadius_small: 4px;
$borderRadius_medium: 8px;
$borderRadius_large: 16px;
$borderRadius_full: 50%;

$borderSizing_small: 1px;
$borderSizing_medium: 2px;

$color_primary: #FF729F;
$color_secondary: #00CCFF;
$color_tertiary: #003057;
$color_onDominant: #001B3E;
$color_white: #fff;

$colorBrand_facebook: #1877f2;
$colorBrand_instagram: #ac35a9;
$colorBrand_twitter: #1b9df0;
$colorBrand_youtube: #ff0000;
$colorBrand_tiktok: #010101;

$colorDominant_primary: #ff729f;
$colorDominant_secondary: #00ccff;
$colorDominant_tertiary: #003057;
$colorDominant_onDominant: #001b3e;
$colorDominant_white: #fff;

$colorShade_primary-050: #fee7ee;
$colorShade_primary-100: #fec1d6;
$colorShade_primary-200: #ff9aba;
$colorShade_primary-400: #fd5588;
$colorShade_primary-500: #fe3f73;
$colorShade_primary-600: #ec3b6f;
$colorShade_primary-700: #d5366a;
$colorShade_primary-800: #bf3166;
$colorShade_primary-900: #9a285d;

$colorShade_secondary-050: #dff7ff;
$colorShade_secondary-100: #ace9fe;
$colorShade_secondary-200: #74dbfd;
$colorShade_secondary-400: #00c0ff;
$colorShade_secondary-500: #00b4ff;
$colorShade_secondary-600: #00a6f4;
$colorShade_secondary-700: #0093e1;
$colorShade_secondary-800: #0081cd;
$colorShade_secondary-900: #0061ac;

$colorShade_tertiary-025: #f1f5f9;
$colorShade_tertiary-050: #e6ebf0;
$colorShade_tertiary-100: #becddc;
$colorShade_tertiary-200: #95acc5;
$colorShade_tertiary-300: #6c8cad;
$colorShade_tertiary-400: #4a739d;
$colorShade_tertiary-500: #1e5c90;
$colorShade_tertiary-600: #155487;
$colorShade_tertiary-700: #064a7f;
$colorShade_tertiary-800: #00416f;

$colorEvolution_up: #30E2BF;
$colorEvolution_down: #E93030;

$colorChart_1: #F22254;
$colorChart_2: #FC8A21;
$colorChart_3: #FFD166;
$colorChart_4: #27EECA;
$colorChart_5: #00CCFF;
$colorChart_6: #02C39A;
$colorChart_7: #0092E3;
$colorChart_8: #8F00FF;
$colorChart_9: #5216D2;
$colorChart_10: #311657;

$table-header-shadow: 2px 0px 7px $color_tertiary 50%;

$sizing_xxx-small: 8px;
$sizing_xx-small: 16px;
$sizing_x-small: 24px;
$sizing_x-small-var: 20px;
$sizing_small: 32px;
$sizing_medium: 40px;
$sizing_large: 48px;
$sizing_x-large: 56px;
$sizing_xx-large: 64px;

$spacing_xxx-small: 8px;
$spacing_xx-small: 16px;
$spacing_x-small: 24px;
$spacing_small: 32px;
$spacing_medium: 40px;
$spacing_large: 48px;
$spacing_x-large: 56px;
$spacing_xx-large: 64px;

$font-family: "Roboto", sans-serif;
$font-family-heading: "Playfair Display",serif;

$fontWeight_regular: 400;
$fontWeight_medium: 500;
$fontWeight_bold: 700;

$fontSize_1: 12px;
$fontSize_2: 13px;
$fontSize_3: 14px;
$fontSize_4: 16px;
$fontSize_5: 18px;
$fontSize_6: 24px;
$fontSize_7: 28px;
$fontSize_8: 32px;
$fontSize_9: 38px;

$lineHeight_heading: 1.25;
$lineHeight_var-text: 1.35;
$lineHeight_text: 1.5;

@mixin buttonLabel_large {
  font-family: $font_family;
  font-weight: $fontWeight_medium;
  font-size: $fontSize_4;
}

@mixin buttonLabel_default {
  font-family: $font_family;
  font-weight: $fontWeight_medium;
  font-size: $fontSize_3;
}

@mixin inputLabel_large {
  font-family: $font_family;
  font-weight: $fontWeight_medium;
  font-size: $fontSize_4;
  color: $colorShade_secondary-900
}

@mixin inputLabel_default {
  font-family: $font_family;
  font-weight: $fontWeight_medium;
  font-size: $fontSize_3;
  color: $colorShade_secondary-900
}

@mixin text_default {
  font-family: $font_family;
  font-weight: $fontWeight_regular;
  font-size: $fontSize_4;
  line-height: $lineHeight_text;
  color: $colorDominant_tertiary
}

@mixin text_secondary {
  font-family: $font_family;
  font-weight: $fontWeight_regular;
  font-size: $fontSize_4;
  line-height: $lineHeight_text;
  color: $colorShade_tertiary-400
}

@mixin page-title {
  font-family: $font_family_heading;
  font-weight: $fontWeight_medium;
  font-size: $fontSize_9;
  line-height: $lineHeight_heading;
  color: $colorShade_tertiary-600
}

@mixin header-row-title {
  font-family: $font_family;
  font-size: $fontSize_4;
  line-height: $lineHeight_var-text;
  color: $colorDominant_tertiary
}

@mixin kol_identity {
  font-family: $font_family;
  font-weight: $fontWeight_bold;
  font-size: $fontSize_4;
  line-height: $lineHeight_text;
  color: $colorDominant_tertiary
}

@mixin kol_username {
  font-family: $font_family;
  font-weight: $fontWeight_regular;
  font-size: $fontSize_3;
  line-height: $lineHeight_text;
  color: $colorShade_tertiary-500
}

@mixin chart_legend-hightlight {
  font-family: $font_family;
  font-weight: $fontWeight_regular;
  font-size: $fontSize_3;
  line-height: $lineHeight_text;
}

@mixin tooltip_text {
  font-family: $font_family;
  font-weight: $fontWeight_regular;
  font-size: $fontSize_3;
  line-height: $lineHeight_text;
}
