@import '../../../css/scss/vars';

.bc-header {
    position: fixed;
    background-color: white;
    height: $navHeight;
    padding: 0 15px;
    width: 100%;
    transition: box-shadow 0.2s ease-out;
    will-change: box-shadow;
    z-index: 150;

    &--sticky {
        box-shadow: 0 3px 5px 0 rgba($darkblue, 0.1);
    }
}

.bc-header__profile-picture-container {
    position: relative;
}

.bc-header__profile-picture {
    // TODO: change with the default img
    background: $darkblue no-repeat center;
    background-attachment: inherit;
    cursor: pointer;

    &.bc-header__profile-picture--empty {
        background-color: white;
        background-image: url('../../../assets/images/defaultUser.png');
        background-size: 25px;
        background-position: bottom;
    }

    @media (max-width: 1199px) {
        display: none;
    }

    @media (min-width: 1200px) {
        width: 35px;
        height: 35px;
        background-size: 35px;

        border-radius: 20px;
        border-width: 0;
    }
}

.bc-header__language-selector-container {
    display: flex;
    align-items: center;
}

.bc-header__right-container {
    display: flex;
    width: 220px;
    justify-content: flex-end;
    @media (max-width: 1199px) {
        display: none;
    }
}

.bc-header__feedback {
    display: flex;

    color: white;
    font-family: $f-light;
    font-size: 12px;

    @media (max-width: 1199px) {
        margin-right: 10px;
    }

    @media (min-width: 1200px) {
        margin-right: 24px;
    }
}

.bc-header__feedback-content {
    display: flex;
    align-items: center;

    color: $darkblue;

    svg {
        fill: $darkblue;

        @media (max-width: 1199px) {
            width: 22px;
            height: 22px;
        }

        @media (min-width: 1200px) {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
}

.section-header-body {
    margin: auto;
    max-width: 1280px;
}
