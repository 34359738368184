@import '../../../css/scss/vars';

//***************** OLD MOADLES *********************** //

// .bc-modal--open      - body class for killing the scroll
// .bc-modal            - container to scroll within
// .bc-modal__dialog    - positioning shell for the actual modal
// .bc-modal__content   - actual modal w/ bg and corners and stuff

// Kill the scroll on the body
.bc-modal--open {
    overflow: hidden;
}

// Container that the modal scrolls within
.bc-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    display: none;
    overflow: hidden;
    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;
    // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
    // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
    // See also https://github.com/twbs/bootstrap/issues/17695

    &--show {
        display: block;
    }

    .bc-modal--open & {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

// Shell div to position the modal with bottom padding
.bc-modal__dialog {
    position: relative;
    width: auto;
    margin: 10px auto;
    // allow clicks to pass through for custom click handling to close modal
    pointer-events: none;

    @media (min-width: 768px) and (max-width: 990px) {
        max-width: 500px;
        margin: 120px auto;
    }

    @media (min-width: 991px) and (max-width: 1199px) {
        max-width: 500px;
        margin: 30px auto 120px;
    }

    @media (min-width: 1200px) {
        max-width: 500px;
        margin: 120px auto;
    }

    // When fading in the modal, animate it to slide down
    .bc-modal.bc--fade & {
        transition: transform 0.3s ease-out;
        transform: translate(0, -25%);
    }
    .bc-modal.bc--show & {
        transform: translate(0, 0);
    }
}

.bc-modal__dialog--full-screen {
    @media (max-width: 767px) {
        margin: 0;
        max-width: 100%;
        min-height: 100vh;

        .bc-modal__content {
            min-height: 100vh;
        }
    }
}

// Actual modal
.bc-modal__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
    // counteract the pointer-events: none; in the .modal-dialog
    pointer-events: auto;
    background-color: white;
    background-clip: padding-box;

    //border: 1px solid rgba(black,.2);
    //@include border-radius($border-radius-lg);
    //@include box-shadow($modal-content-box-shadow-xs);

    // Remove focus outline from opened modal
    outline: 0;
}

.bc-modal-content__snaform {
    width: 100%;
    max-width: 100%;
    color: $darkblue;
    @media (min-width: 768px) {
        width: 336px;
    }
}

.bc-modal__content {
    text-align: center;

    @media (min-width: 768px) {
        border-radius: 6px;
        box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.1), 0 24px 24px 0 rgba(0, 48, 87, 0.2);
    }
    .bnc_field_input_gmap .bnc_field_input_gmap_predictions ul li button {
        font-size: 13px;
    }
}

// Modal background
.bc-modal__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: rgba(0, 48, 87, 0.5);

    // Fade for backdrop
    &.bc--fade {
        opacity: 0;
    }
    &.bc--show {
        opacity: 1;
    }
}

.bc-modal-content {
    @media (min-width: 768px) {
        width: 400px;
    }

    &__instaconnect {
        margin: 20px auto;
    }

    .bc-modal__header {
        @media (max-width: 767px) {
            padding: 10px 10px 0;
        }

        @media (min-width: 768px) and (max-width: 990px) {
            padding: 15px 10px 0;
        }

        @media (min-width: 991px) and (max-width: 1199px) {
            padding: 15px 10px 10px;
        }

        @media (min-width: 1199px) {
            padding: 10px;
        }
    }

    .bc-modal__body {
        color: $darkblue;
        font-family: $f-regular;
        font-size: 18px;

        @media (max-width: 767px) {
            padding: 30px 20px 55px;
        }

        @media (min-width: 768px) {
            padding: 20px 40px 40px;
        }

        p {
            line-height: 21px;
        }
    }
}

.bc-modal__header__icon {
    padding: 0;
    background: transparent;
    border: 0;

    float: left;
    font-size: 30px;
    line-height: 20px;
    color: #000;
    cursor: pointer;
    opacity: 0.5;
    font-family: $f-light;

    svg {
        width: 20px;
        height: 20px;
    }

    &.bc-modal__header__icon--right {
        float: right;
        width: inherit;
    }

    &.bc-modal__close {
        margin-left: 2px;
        margin-right: 2px;
    }
}

.bc-modal-content__btn-container {
    text-align: center;

    &.bc-modal-content__btn-container--fetchurl {
        text-align: right;

        @media (max-width: 767px) {
            margin-top: 10px;
        }

        @media (min-width: 768px) {
            margin-top: 20px;
            .bnc_field_button {
                &::before {
                    background-color: $darkblue !important;
                }
            }
        }

        .bnc_field_button {
            display: inline-block;
            min-width: 97px;
            width: 100%;
            height: 40px;
            background-color: $lighterBlue;
            &::before {
                background-color: $darkblue !important;
            }
        }
    }
}

.bc-modal-content__img {
    margin: auto;
    width: 137px;
    height: auto;
    padding-bottom: 10px;

    img {
        width: 100%;
        height: auto;
    }

    @media (max-width: 767px) {
        margin-top: 0;
        width: 45%;
    }
}

.bc-modal-content__title {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 767px) {
        margin-bottom: 20px;
    }

    @media (min-width: 768px) {
        margin-bottom: 25px;
    }

    h3 {
        margin-bottom: 0;

        font-size: 22px;
        text-align: center;
        font-family: $f-regular;
    }
}

.bc-modal-content__thanks-title {
    color: $darkblue;
    font-family: $f-regular;
    line-height: 37px;
    text-align: center;
    margin: 20px 40px 28px;

    h3 {
        margin-bottom: 0;

        font-size: 30px;
        text-align: center;
    }
}

.bc-modal-content__bullet-points {
    display: flex;
    flex-direction: row;
    margin: 25px auto;

    &:before,
    &:after {
        content: '';
        flex: 1;
    }
}

.bc-modal-content__bullet-point {
    height: 8px;
    width: 8px;
    background-color: #d8d8d8;
    border-radius: 4px;

    &:not(:first-child) {
        margin-left: 7px;
    }

    &--active {
        background-color: #00ccff;
    }
}

.bc-modal-network__title h3 {
    font-size: 20px;
}

.bc-modal-connect-network__text {
    &.bc-modal-content__text p {
        text-align: left;
        width: 100%;
        margin: 0;
    }
}

.bc-modal-content__text {
    p {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        font-family: $f-light;
        font-size: 13px;
        line-height: 18px;
    }
}

.bc-modal-content__btn {
    text-transform: uppercase;
    text-align: center;
    height: 40px;
    width: 170px;
    border: 1px solid #1f2f39;
    border-radius: 2px;
    background-color: #1f2f39;
    color: white;
    cursor: pointer;
    font-family: $f-500;
    font-size: 12px;
    line-height: 14px;
    margin-top: 30px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
        height: 50px;
        margin-bottom: 15px;
    }
}

.bc-modal-button-margin {
    margin-top: 30px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
        margin-bottom: 15px;
    }
}

.bc-modal-content__btn--tall {
    @media (max-width: 767px) {
        margin-top: 30px;
    }
    @media (min-width: 768px) {
        margin-top: 50px;
    }
}

.bc-error-label {
    display: block;
    color: #d00202;
    font-size: 12px;
    margin-top: 4px;
}

.bc-modal-content__btn-container--instagram {
    margin: 30px 0;

    @media (max-width: 767px) {
        margin-bottom: 15px;
    }
}

button.bc-modal-content__btn-instagram {
    appearance: none;
    display: block;
    padding: 0;
    margin: 0 auto;
    width: 250px;
    height: 50px;

    border: none;
    border-radius: 2px;
    cursor: pointer;
    background-color: #5d84aa;
    font-family: $f-regular;
    font-size: 13px;
    color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    &.btn-instagram--small {
        width: auto;
        height: auto;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 15px;
        padding-right: 20px;
    }
    /**
   * Fix a bug on iPad/mobile
   * On these devices `display`, `align-items`, and `justify-content` don't work well with <button>
   * Something is breaking them somewhere else in the CSS and even with !important this doesn't work. Because <div>
   * works fine with `display` and others, we wrap our content with it to fix the bug.
   */
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            fill: #fff;
            width: 22px;
            height: 22px;
            margin-right: 15px;
        }
    }
    &.is--business {
        text-align: left;
    }
}

.bnc_field.bnc_field_button {
    display: inline-block;
}

.instagram-account {
    display: flex;
    justify-content: center;
    align-items: center;
    .instagram-account_picture {
        width: 40px;
        height: 40px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 5px;
        margin-right: 10px;
        background-color: #fff;
        img {
            position: absolute;
            width: 100%;
            height: auto;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.bc-modal-content__ignore-link {
    button {
        background: none;
        border: none;
        color: $darkblue;
        font-family: $f-light;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
    }
}

.bc-modal-addsna__input-list {
    &__input {
        position: relative;
        margin: 10px 0;
        transition: margin-bottom linear 0.2s;
        &--error {
            margin-bottom: 30px;
        }
        &__remove-btn {
            position: absolute;
            top: 4px;
            right: 4px;
            padding: 0;
            z-index: 10;
            border: none;
            background-color: $disabled;
            cursor: pointer;
            svg {
                width: 15px;
                height: 15px;
                fill: #afbdcc;
            }
        }
    }

    text-align: left;
    &__button {
        min-width: 160px;
        height: 36px;
    }
    &__button-handler {
        height: 50px;
        width: 100%;
        text-align: center;
        margin: 20px auto;
    }

    .bc-modal-addsna__input-error {
        margin-bottom: 30px;
    }

    .bnc_field:not(:first-of-type) {
        margin-top: 10px;
    }

    label {
        font-family: $f-light;
    }

    .bnc_field .help-text {
        bottom: -5px;
    }
}

.bc-modal-content-custom {
    @media (min-width: 768px) {
        width: 400px;
    }

    .bc-modal__header {
        @media (max-width: 767px) {
            padding: 10px 10px 0;
        }

        @media (min-width: 768px) and (max-width: 990px) {
            padding: 15px 10px 0;
        }

        @media (min-width: 991px) and (max-width: 1199px) {
            padding: 15px 10px 10px;
        }

        @media (min-width: 1199px) {
            padding: 10px;
        }
    }

    .bc-modal-content-header__steps {
        align-self: center;
        padding: 4px 14px;

        border-radius: 12px;
        background-color: #40e6ff;
        color: white;
    }

    .bc-modal__body {
        color: $darkblue;
        font-family: $f-regular;
        font-size: 18px;

        @media (max-width: 767px) {
            padding: 30px 20px 55px;
        }

        @media (min-width: 768px) {
            padding: 20px 40px 40px;
        }

        p {
            line-height: 21px;
        }
    }

    .bc-modal-content__btn-container {
        text-align: center;

        button {
            width: 100%;
            margin: 0;
        }

        &.bc-modal-content__btn-container--fetchurl {
            text-align: right;

            @media (max-width: 767px) {
                margin-top: 10px;
            }

            @media (min-width: 768px) {
                margin-top: 20px;
            }

            button {
                display: inline-block;
                min-width: 97px;
            }
        }
    }

    .bc-modal-content--btn-container__uptext {
        margin-bottom: 14px;

        color: #003057;
        text-align: left;
        //font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    .bc-modal-content__title {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }

        @media (min-width: 768px) {
            margin-bottom: 30px;
        }

        &.bc-modal-content__title--or {
            margin-top: 40px;
            font-family: $f-300;
            font-size: 14px;
            color: #000000;
            justify-content: center;
            align-items: center;

            h4 {
                margin: 0;
                padding: 0;
            }

            &:before,
            &:after {
                width: 20px;
                height: 1px;
                flex: none;
                display: inline-block;
                margin: 0;
                padding: 0;
            }

            &:before {
                margin-right: 15px;
            }

            &:after {
                margin-left: 15px;
            }
        }

        h3 {
            margin-bottom: 0;

            //font-size: 16px;
            text-align: center;
            font-family: $f-300;

            color: #003057;
            font-size: 24px;
            line-height: 28px;
        }
    }

    .bc-modal-content__thanks-title {
        color: $darkblue;
        font-family: $f-light;
        line-height: 37px;
        text-align: center;
        margin: 20px 40px 28px;

        h3 {
            margin-bottom: 0;

            text-align: center;

            font-size: 24px;
            line-height: 28px;
        }

        b {
            font-family: $f-regular;
        }
    }

    .bc-modal-connect-network__text {
        &.bc-modal-content__text p {
            text-align: left;
            width: 100%;
            margin: 0;
        }
    }

    .bc-modal-content__text {
        margin-top: 25px;

        p {
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;

            font-family: $f-regular;
            font-size: 16px;
            line-height: 20px;

            &:not(:first-of-type) {
                margin-top: 27px;
            }

            b {
                font-family: $f-medium;
            }
        }
    }

    .bc-modal-content__btn {
        text-transform: uppercase;
        text-align: center;
        height: 40px;
        width: 170px;
        border: 1px solid #1f2f39;
        border-radius: 2px;
        background-color: #1f2f39;
        color: white;
        cursor: pointer;
        font-family: $f-500;
        font-size: 12px;
        line-height: 14px;
        margin-top: 30px;
        margin-bottom: 30px;

        @media (max-width: 767px) {
            height: 50px;
            margin-bottom: 15px;
        }
    }

    .bc-modal-button-margin {
        margin-top: 30px;
        margin-bottom: 30px;

        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }

    .bc-modal-content__btn--tall {
        @media (max-width: 767px) {
            margin-top: 30px;
        }
        @media (min-width: 768px) {
            margin-top: 50px;
        }
    }

    .bc-error-label {
        display: block;
        color: #d00202;
        font-size: 12px;
        margin-top: 4px;
    }

    .bc-modal-content__btn-container--instagram {
        margin-top: 30px;
        margin-bottom: 55px;

        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }

    button.bc-modal-content__btn-instagram {
        appearance: none;
        display: block;
        padding: 0;
        margin: 0 auto;
        width: initial;

        border: none;
        border-radius: 4px;
        cursor: pointer;
        background-color: $instagram;
        font-family: $f-regular;
        font-size: 13px;
        color: #ffffff;
        padding: 8px 20px;

        &.btn-instagram--small {
            width: auto;
            height: auto;
            padding: 8px 20px 8px 15px;
        }

        /**
     * Fix a bug on iPad/mobile
     * On these devices `display`, `align-items`, and `justify-content` don't work well with <button>
     * Something is breaking them somewhere else in the CSS and even with !important this doesn't work. Because <div>
     * works fine with `display` and others, we wrap our content with it to fix the bug.
     */
        .bc-modal-content-btn-instagram__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                fill: #fff;
                width: 22px;
                height: 22px;
                margin-right: 15px;
            }

            div {
                display: block;
                text-align: left;
            }
        }

        &.is--business {
            text-align: left;
        }

        .bc-modal-content-btn-instagram-wrapper__label {
            color: #ffffff;
            font-family: $f-medium;
            font-size: 14px;
            line-height: 20px;
        }

        .bc-modal-content-btn-instagram-wrapper__subtext {
            margin-top: 2px;
            font-family: $f-light;
            font-size: 12px;
        }
    }

    .bc-modal-content-btn-instagram__subtext {
        margin: 10px 40px 0 40px;

        //font-family: Roboto;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
    }

    .bnc_field.bnc_field_button {
        display: inline-block;
    }

    .instagram-account {
        display: flex;
        justify-content: center;
        align-items: center;

        .instagram-account_picture {
            width: 40px;
            height: 40px;
            position: relative;
            border-radius: 50%;
            overflow: hidden;
            margin-left: 5px;
            margin-right: 10px;
            background-color: #fff;

            img {
                position: absolute;
                width: 100%;
                height: auto;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .bc-modal-content__text-thanks {
        margin-bottom: 71px;
    }

    .bc-modal-addsna__input-list {
        margin-top: 12px;
        margin-bottom: 25px;

        text-align: left;

        .bc-modal-addsna__input-error {
            margin-bottom: 30px;
        }

        .bnc_field:not(:first-of-type) {
            margin-top: 10px;
        }

        label {
            font-family: $f-light;
        }

        .bnc_field .help-text {
            bottom: -5px;
        }
    }

    .bc-modal-content-input-list__label {
        margin-bottom: 3.5px;

        color: #003057;
        font-family: $f-medium;
        font-size: 12px;
        text-transform: uppercase;
    }
}

// TODO: removed when fixed in the UIKit
.bnc_field_select .bnc_field_select_options .bnc_field_select_options_mask {
    display: none;

    &.deployed,
    &.rendered {
        display: inherit;
    }
}

//**************** END OLD MODALEs ******************* //

.modale-container {
    .modale__title-container {
        display: flex;
        justify-content: space-between;

        /* MOBILE */
        @media screen and (max-width: 640px) {
            margin-bottom: 10px;
            &__title {
                font-size: 13px;
            }
        }

        .title-container__title {
            flex-grow: 2;
            justify-content: center;
            display: flex;
            align-items: flex-end;
            font-size: 18px;
            color: #003057;
            font-weight: bold;
            padding-left: 62px;
            text-align: center;
            @media screen and (max-width: 640px) {
                font-size: 12px;
            }
        }
        .modale__btn-close {
            cursor: pointer;
            padding: 12px;
            border-radius: 60px;
            margin: 10px;
            svg {
                font-size: 18px;
            }
        }
    }

    .modale__content {
        &-container {
            margin: 20px 60px;
            display: flex;
            flex-direction: column;
            padding: 20px;
            align-items: center;
            text-align: center;
            line-height: 24px;

            p {
                margin: 0;
                text-align: center;
            }

            /* MOBILE */
            @media screen and (max-width: 640px) {
                margin: 0;
                padding: 0 20px 20px 20px;
            }

            &__button {
                margin-top: 20px;
                button {
                    @media (max-width: 720px) {
                        // @TODO real refacto of this shit but no time now... sorry... ras le bol...
                        min-width: 60%;
                    }
                    &:first-child {
                        margin-right: 20px;
                        @media (max-width: 720px) {
                            // @TODO real refacto of this shit but no time now... sorry... ras le bol...
                            margin-right: 0;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            &__text {
            }
        }

        &__input {
            min-width: 200px;
        }
    }
}
