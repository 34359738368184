@import '../../../../css/scss/vars';

.bc-upgrade-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding: 20px;

    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.1), 0 16px 16px 0 rgba(10, 31, 68, 0.15);
    border-radius: 4px;
    background-color: #003057;
    color: #fff;
    z-index: 999;

    @include respond-to(mobile) {
        bottom: 0;
    }

    @media (min-width: 768px) {
        max-width: 410px;
        bottom: 39px;
        right: 30px;
    }

    .bc-upgrade-modal__text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
