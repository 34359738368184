@import '../../../css/scss/vars';

.banner {
    box-sizing: border-box;
    max-width: 1000px;
    margin: 20px auto;
    padding: 0 20px;
    min-height: 80px;
    &__handler {
        border: 1px solid $lightblue;
        border-radius: 4px;
        background-color: #00ccff33;
        display: flex;
        min-height: 80px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        @include respond-to(mobile) {
            flex-direction: column;
        }
    }
}
