@import 'css/scss/_vars';

.wrapper {
    width: 254px;
    @include respond-to(mobile) {
        width: 100%;
    }
}

.title {
    color: rgba($darkblue, 0.6);
    font-family: $f-500;
    font-size: 11px;
    line-height: 14px;
}

.content {
    display: flex;
}

.post {
    margin-right: 8px;
    text-align: center;
    &:last-child {
        margin-right: 0;
    }
    @include respond-to(mobile) {
        width: calc(100% / 3);
    }
}

.link {
    display: block;
}

.image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
}

.imgContainer {
    width: 72px;
    height: 72px;
    background-color: $lightGrey;
    overflow: hidden;
    padding-bottom: 100%;
    border-radius: $radius;
    @include respond-to(mobile) {
        width: 100%;
    }
}

.date {
    color: $darkblue;
    font-size: 11px;
    font-family: $f-bold;
}
