@import '../../css/scss/vars';

.profile-page-sidebar {
    width: 235px;

    .profile-strength-metter {
        @media only screen and (max-width: 767px) {
            margin: 0 20px 20px;
        }
    }

    .profile-page-sna {
        background-color: white;
        box-shadow: 0 0 4px 0 rgba(10, 31, 68, 0.1);
        border-radius: 4px;
        padding: 12px;

        @media only screen and (max-width: 767px) {
            margin: 0 20px 20px;
        }

        .profile-page-sidebar__title {
            color: $darkblue;
            font-size: 16px;
            line-height: 19px;
        }

        .profile-sna__item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .profile-sna__delete {
                opacity: 0;
                transition: opacity 0.1s ease-out;
                svg {
                    width: 12px;
                    fill: $metalblue;
                }
            }

            &:hover {
                .profile-sna__delete {
                    opacity: 1;
                }
            }

            &.snapchat-item {
                .social-name {
                    svg {
                        background-color: $snapchat;
                    }
                }
            }

            .social-name__label {
                max-width: 152px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 0;
            }
        }

        .profile-page-sidebar__edit {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px auto;
            &:focus {
                outline: none;
            }
        }
    }
}

@include respond-to(tablet-portrait) {
    .profile-page-sidebar {
        width: 100%;
    }
}
