@import 'css/scss/_vars2.scss';

.backButton {
  display: flex;
  align-items: center;
  width: 100%;
  padding: $tinySpace;
  border-bottom: $border;

  @media only screen and (min-width: $m) {
    &.onlyMobile {
      display: none;
    }
  }
}
