@import '../../../css/scss/vars';

.loader {
    border: 4px solid $pink;
    border-radius: 50%;
    border-top: 4px solid transparent;
    width: 42px;
    height: 42px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
