@import '../../../../css/scss/vars';

.bc-analytics-community-age-chart {
    padding: 30px 20px;

    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(10, 31, 68, 0.1);
}

.bc-analytics-community-age-chart__title {
    margin-bottom: 30px;

    font-family: $f-light;
    font-size: 15px;
    text-transform: uppercase;
}

.bc-analytics-community-age-chart__age-chart {
    width: 100%;
    margin: auto;
    max-width: 600px;

    .bc-analytics-community-age-chart__single-chart {
        height: 100px;
        width: 100%;

        .bc-analytics-community-age-chart__chart {
            height: 100px;
            width: 100%;

            .bc-google-chart-container {
                height: 100px;
                width: 100%;

                .bc-google-chart {
                    height: 100px;
                    width: 100%;
                }
            }
        }
    }
}

.bc-analytics-community-age-chart__age-chart-placeholder {
    text-align: center;
    font-family: $f-light;

    @include respond-to(mobile) {
        font-size: 12px;
    }
}

.bc-analytics-community-age-chart__single-chart {
    display: flex;

    &--male {
        .bc-analytics-community-age-chart__legend-container {
            align-self: flex-start;
        }
    }
}

.bc-analytics-community-age-chart__legend-container {
    align-self: flex-end;
    width: 80px;
    margin-right: 5px;

    @include respond-to(mobile) {
        display: none;
    }
}

.bc-analytics-community-age-chart__legend-title {
    margin-bottom: 2px;

    font-family: $f-medium;
    font-size: 13px;
}

.bc-analytics-community-age-chart__legend-metric {
    display: flex;
    align-content: center;

    font-family: $f-light;
    font-size: 13px;

    svg {
        width: 9px;
        height: 9px;
        margin-right: 5px;
        align-self: center;
    }
}

.bc-analytics-community-age-chart__age-axis {
    display: flex;
    justify-content: space-around;

    text-align: center;

    @include respond-to(mobile) {
        margin: 12px 0;
    }

    @media (min-width: 768px) {
        margin: 12px 0 12px 75px;
    }
}

.bc-analytics-community-age-chart__age-axis-item {
    width: 100%;

    font-family: $f-regular;
    font-size: 12px;
    text-align: center;
}

.bc-analytics-community-age-chart__bottom-infos {
    font-family: $f-light;
    font-size: 10px;
    line-height: 19px;
    text-align: right;
}

.bc-analytics-community-age-chart__legend-mobile-container {
    @include respond-to(mobile) {
        display: flex;
        justify-content: space-evenly;
    }

    @media (min-width: 768px) {
        display: none;
    }
}

.bc-analytics-community-age-chart__legend-mobile-title {
    margin-bottom: 2px;

    color: $darkblue;
    font-family: $f-medium;
    font-size: 13px;
}

.bc-analytics-community-age-chart__legend-mobile-metric {
    display: flex;
    align-content: center;

    color: $darkblue;
    font-family: $f-light;
    font-size: 13px;

    svg {
        width: 9px;
        height: 9px;
        margin-right: 5px;
        align-self: center;
    }
}
