@import '../../css/scss/vars';

.home {
    max-width: 1000px;
    padding: 0 18px;

    .block {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        @include respond-to(mobile) {
            flex-direction: column;

            &.reverseOnMobile {
                flex-direction: column-reverse;
                > :last-child {
                    margin: 0 0 20px;
                }
            }
        }

        .left {
            flex: 0 0 235px;
            margin-right: 19px;
            padding: 2px;

            @include respond-to(mobile) {
                flex: 1;
                margin: 0 0 20px;
            }

            @media (min-width: 768px) and (max-width: 1199px) {
                flex: 0 0 235px;
                margin-right: 5px;
            }

            @media (min-width: 1200px) {
                flex: 0 0 235px;
            }
        }
        .right {
            flex: 1;
            overflow: hidden;
            padding: 2px;

            @media (min-width: 768px) and (max-width: 1199px) {
                margin-left: 5px;
            }
        }

        &.recap {
            flex-direction: column;
            margin-bottom: 0;
        }
    }

    @include respond-to(mobile) {
        margin: 20px auto;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        margin: 32px auto;
        flex-wrap: nowrap;
    }

    @media (min-width: 1200px) {
        margin: 32px auto;
    }
}
