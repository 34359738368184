@import '../../../css/scss/vars';

.instaconnect {
    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }

        @media (min-width: 768px) {
            margin-bottom: 0;
        }

        h3 {
            margin-bottom: 0;

            font-size: 22px;
            text-align: center;
            font-family: $f-regular;
        }

        p {
            font-size: 16px !important; // TODO: remove !important
        }
    }
    &__button {
        margin-top: 20px;
    }

    &__congratulations {
        h3 {
            font-size: 18px;
        }
        p {
            font-size: 12px;
        }
    }

    &__text {
        @media (max-width: 767px) {
            margin-bottom: 20px;
        }

        @media (min-width: 768px) {
            margin-bottom: 25px;
        }
        p {
            margin-bottom: 0;
            text-align: left;
            font-family: $f-light;
            font-size: 13px;
            line-height: 18px;
            & b,
            strong {
                font-family: $f-regular;
                font-weight: bold;
            }
        }
    }
    &__warning {
        margin: 15px 10px;
        & p {
            font-size: 13px;
            text-align: center;
            font-family: $f-regular;
            letter-spacing: -0.3px;
        }
    }
    &__gnee {
        margin: 20px !important;
        &__link {
            text-decoration: underline;
            color: $greyBoulder;
            font-size: 14px;
            font-weight: 300;
        }
    }
}
