@import '../../../css/scss/vars';

.bc-proposition-details {
    padding: 25px 12px 25px;
    width: 100%;

    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);

    @include respond-to(mobile) {
        border-left: 5px solid #1f2f39;
    }

    @media (min-width: 768px) {
        border-top: 5px solid #1f2f39;
    }
}

.bc-proposition-details__title {
    text-align: center;

    height: 20px;
    width: 211px;
    color: #4a4a4a;
    font-family: $f-regular;
    font-size: 16px;
    line-height: 19px;

    @include respond-to(mobile) {
        margin: auto auto 10px;
        font-size: 17px;
    }

    @media (min-width: 768px) {
        margin-bottom: 10px;
    }
}

.bc-proposition-details__body {
    font-family: $f-light;
    line-height: 23px;

    @include respond-to(mobile) {
        display: flex;
        justify-content: space-around;
        text-align: center;
    }

    @media (min-width: 768px) {
        padding-left: 21px;
    }
}

.bc-proposition-details__detail {
    display: flex;

    @include respond-to(mobile) {
        flex-direction: column;
        width: 33%;
    }
}

.bc-proposition-details__detail-subtitle {
    text-transform: uppercase;

    color: #9b9b9b;
    font-size: 12px;

    @include respond-to(mobile) {
        margin-bottom: 0;
    }

    @media (min-width: 768px) {
        margin-bottom: 3px;
    }

    svg {
        position: relative;
        top: 1px;
        width: 10px;
        height: 10px;
        margin-right: 3px;

        fill: #9b9b9b;
    }
}

.bc-proposition-details__detail-text {
    text-transform: none;
    margin-bottom: 0;

    color: $greyBoulder;
    font-size: 12px;

    @include respond-to(mobile) {
        line-height: 16px;
    }

    @media (min-width: 768px) {
        margin-left: 5px;
    }
}

.bc-proposition-details__detail-social-networks {
    svg {
        height: 13px;
        margin-top: 4px;
        margin-left: 10px;
    }
}
