@import '../../../css/scss/vars';

.bc-progress-metter {
    position: relative;
    background-color: rgba($darkblue, 0.1);
    height: 8px;
    width: 100%;
    border-radius: 4px;
    margin: 50px 0 18px 0;

    &__tooltip {
        position: absolute;
        transform: translateX(-50%);
        transition: left 0.5s;
        box-shadow: 0 0 4px 0 rgba($darkblue, 0.4);
        border-radius: 4px;
        background-color: white;
        font-size: 14px;
        font-weight: bold;
        padding: 0 5px;
        bottom: 16px;
        height: 28px;
        line-height: 28px;

        &-arrow {
            width: 100%;
            height: 10px;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                background: white;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
                top: 0;
                left: 50%;
                box-shadow: 0 0 4px 0 rgba($darkblue, 0.4);
            }
        }
    }

    &__bar {
        height: 100%;
        border-radius: 4px;
        background-color: $green;
        transition: width 0.5s;
    }
}
