@import 'css/scss/_vars';

.page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    @media (min-width: $break-mobile) {
        min-height: 820px;
    }
}
.card {
    max-width: 620px;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    @media (min-width: $break-mobile) {
        padding: 56px;
    }
}
.title {
    font-family: 'Playfair Display';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
}
.description {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
}
.linkSentCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $space;
    max-width: 327px;
    background: rgba(255, 228, 226, 0.5);
    border-radius: $bigRadius;
    line-height: 18px;
    &Email {
        font-weight: 600;
    }
}
.emailNotReceived {
    margin: $largeSpace 0;
}
.deleteAccount {
    margin: $largeSpace 0;
}
.ctas {
    width: 100%;
    display: flex;
    flex-direction: column;
    .cta {
        margin-bottom: $space;
    }
}