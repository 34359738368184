@import '../../../../css/scss/vars';

.bc-analytics-search-card {
    width: 100%;
    margin: auto;

    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(10, 31, 68, 0.1);

    @include respond-to(mobile) {
        padding: 15px 7px;
    }

    @media (min-width: 768px) {
        padding: 20px;
    }
}

.bc-analytics-search-card__row {
    display: flex;
    justify-content: space-between;

    @include respond-to(mobile) {
        flex-direction: column;

        &:not(:first-of-type) {
            margin-top: 15px;
        }
    }

    @media (min-width: 768px) {
        &:not(:first-of-type) {
            margin-top: 10px;
        }
    }
}

.bc-analytics-search-card__search-input {
    @media (min-width: 768px) {
        width: 386px;
    }

    label {
        margin: 0;
    }
}

.bc-analytics-search-card__rating {
    @include respond-to(mobile) {
        margin-top: 15px;
    }

    @media (min-width: 768px) {
        width: 220px;
    }

    label {
        margin: 0;
    }
}
