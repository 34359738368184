.root {
  .radios {
    > div {
      margin-bottom: 0 !important;
      height: auto;
      label {
        &::before,
        &::after {
          top: 10px !important;
        }
      }
    }
  }
}
