@import '../../../css/scss/vars';

.profile-strength-metter {
    background-color: white;
    box-shadow: 0 0 4px 0 rgba(10, 31, 68, 0.1);
    border-radius: 4px;
    padding: 12px;
    color: $darkblue;
    margin-bottom: 32px;

    .profile-strength-metter__button {
        justify-content: start;
    }

    &__title {
        @include boxTitle;
    }
    &__subtitle {
        font-size: 12px;
        line-height: 14px;
    }
    &__sections {
        display: flex;
        flex-direction: column;
    }
    &__section {
        display: flex;
        padding: 10px 0;

        svg {
            fill: $green;
        }
    }
    &__section-label {
        margin-left: 8px;
        .section-title {
            font-size: 14px;
            line-height: 16px;
        }
        .section-subtitle {
            margin-top: 2px;
            font-size: 10px;
            line-height: 13px;
            color: rgba($darkblue, 0.6);
        }
    }
    &__full {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-icon {
            background-color: $lightGreen2;
            width: 48px;
            height: 42px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                fill: $green;
                height: 32px;
            }
        }
        &-title {
            font-weight: bold;
            font-size: 14px;
            margin-top: 15px;
        }
        &-subtitle {
            font-size: 12px;
        }
    }
}
