@import 'css/scss/_vars2.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
  width: 100%;
  padding: $space;
  padding: 25% $space $space;

  .placeholderImg {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description {
    max-width: 330px;
    margin-top: $space;
    color: #638198;
    font-weight: 400;
    text-align: center;
    white-space: break-spaces;
  }
}





