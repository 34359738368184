$kolnavy: #003057;
$kolblue: #00ccff;
$kolpink: #ff729f;
$grey: #7F97B0;
$disabled: #d1d1d1;
$white: #fff;
$borderColorLight: #ccd6e3;
$focusShadow: #CCFFFA;
$darkgrey: #EDF1F7;
$greyLynch: #638198;

$border: 1px solid $borderColorLight;

$radius: 4px;
$bigRadius: 12px;

$transition: cubic-bezier(0.260, -0.335, 1.000, 0.565);

$tinySpace: 4px;
$smallSpace: 8px;
$space: 16px;
$bigSpace: 32px;

$reducedSpace: 12px;

$s: 320px;
$m: 768px;
$l: 1024px;
$xl: 1280px;

@mixin textShorten {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin borderFocus {
  outline: none;
  border-color: $kolblue;
  box-shadow: 0 0 0 3px $focusShadow;
}

@mixin resetList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@mixin breakpoint($point) {
  @if $point == large {
    @media only screen and (min-width: $xl) {
      @content;
    }
  }
  @else if $point == desktop {
    @media only screen and (min-width: $l) {
      @content;
    }
  }
  @else if $point == tablet {
    @media only screen and (min-width: $m) {
      @content;
    }
  }
}