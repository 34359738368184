@import '../../../../css/scss/vars';

.button {
    line-height: normal;
    display: inline-block;
    border: solid 1px $metalblue;
    border-radius: 4px;
    font-size: 14px;
    font-family: $f-400;
    font-weight: 500;
    letter-spacing: normal;
    color: $darkblue;
    cursor: pointer;
}
