@import '../../../../css/scss/vars';

.bc-analytics-search-card__social-filters {
    position: relative;
    user-select: none;

    @include respond-to(mobile) {
        width: 100%;
    }

    @media (min-width: 768px) {
        width: 170px;
    }
}

.bc-analytics-search-card-social-filter__placeholder {
    width: calc(100% - 15px);
    overflow: hidden;
    margin-right: 15px;
    font-family: $f-regular;
    font-size: 13px;
    line-height: normal;

    color: $darkblue;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.bc-analytics-search-card-social-filter__arrow {
    margin-right: 4px;

    svg {
        height: 10px;
        width: 9px;
        transition: transform 0.2s ease-out;

        fill: $darkblue;
    }

    &--deployed {
        svg {
            transform: rotate(180deg);
        }
    }
}

.bc-analytics-search-card__social-filter {
    display: flex;
    padding: 9px;

    cursor: pointer;
    border: 1px solid rgba(0, 48, 87, 0.2);
    border-radius: 3px;
    background-color: #ffffff;
}

.bc-analytics-search-card-social-filter__options {
    position: absolute;
    width: 100%;
    z-index: 1000;
    padding-top: 12px;
    padding-bottom: 12px;

    background-color: white;
    border: 1px solid #dddddd;
    border-top: none;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.09);
}

.bc-analytics-search-card-social-filter__option {
    display: flex;
    align-items: center;
    padding: 10px 15px;

    cursor: pointer;

    &:hover {
        background-color: #f2f2f2;
    }
}

.bc-analytics-search-card-social-filter-option__checkbox {
    display: flex;
    justify-items: center;
    margin-right: 7px;
    min-width: 16px;
    height: 16px;

    svg {
        width: 16px;
        height: 16px;
        fill: $lightblue;
    }
}

.bc-analytics-search-card-social-filter-option__icon {
    display: flex;
    justify-content: center;
    margin-right: 5px;

    svg {
        width: 14px;
        height: 14px;
    }
}

.bc-analytics-search-card-social-filter-option__text {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    color: $darkblue;
    font-family: $f-light;
    font-size: 13px;
    letter-spacing: 0.75px;
    line-height: 15px;
}
