@import '../../../../css/scss/vars';

.add-button {
    height: 100%;
    min-height: 100px;
    box-sizing: border-box;
    border: dashed 2px rgba(0, 48, 87, 0.2);
    border-radius: 4px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    &:hover {
        border: 2px solid rgba(0, 48, 87, 0.05);
        background-color: #e5eaee;
        cursor: pointer;
    }
    &__label {
        font-size: 14px;
        line-height: 28px;
        height: 26px;
        font-family: 'Roboto-Medium';
        opacity: 0.6;
        text-align: left;
        width: 100%;
        color: #003057;
        padding-left: 10px;
        flex: 1;
    }
    &__plus {
        margin-left: 10px;
        text-align: center;
        width: 18px;
        opacity: 0.6;
        height: 18px;
        border: solid 2px #003057;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 14px;
            height: 14px;
            color: #003057;
            path: {
                color: #003057;
            }
        }
    }
}
