@import 'css/scss/_vars2.scss';

.container {
  background-color: white;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  width: 50%;
  height: 100%;
  overflow: hidden;

  @media only screen and (min-width: $m) {
    flex-grow: 2;
    width: initial;
  }
}
