@import '../../css/scss/vars';

.confirmationMessage {
    color: #4a4a4a;
    margin-top: $smallmargin;
    font-size: 12px;
    &.messageError {
        color: $red;
    }
}

.submitButtonRight {
    display: flex;
    justify-content: flex-end;
    padding: $smallSpace 0;
}

.socialNetworkLogout {
    h5 {
        margin: 0 0 $smallmargin 0;
        padding: 0;
        font-family: $f-500;
        font-size: 13px;
        color: $greyBoulder;
    }
    p {
        font-size: 13px;
        line-height: 15px;
        color: $greyBoulder;
    }
}

.accountDelete {
    display: flex;
    justify-content: center;
    > .button {
        height: 36px;
    }
}
