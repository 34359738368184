@import '../../../css/scss/vars';

.bc-contact-card {
    width: 100%;
    padding: 30px 20px 35px;

    text-align: center;
    border-top: 5px solid $lightblue;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);
}

.bc-contact-card__title {
    margin-bottom: 5px;

    color: #4a4a4a;
    font-family: $f-regular;
    font-size: 17px;
    line-height: 19px;
}

.bc-contact-card__subtext {
    max-width: 235px;
    margin: auto;

    color: #9b9b9b;
    font-family: $f-light;
    line-height: 13px;

    @include respond-to(mobile) {
        font-size: 13px;
    }

    @media (min-width: 768px) {
        font-size: 11px;
    }
}

.bc-contact-card__picture {
    height: 80px;
    width: 80px;
    margin: 24px auto 20px;

    background-color: #f4f4f4;
    background-image: url('../../../assets/images/defaultUser.png');
    background-size: 80px;
    background-position: center;
    background-repeat: no-repeat;

    border-radius: 40px;
}

.bc-contact-card__infos {
    color: $greyBoulder;
    font-family: $f-light;
    font-size: 13px;
    line-height: 17px;

    a {
        color: $greyBoulder;
    }
}

.bc-contact-card__email {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
