.arrow {
    width: 20px;
    svg {
        width: 8px;
    }
    &-top-right {
        transform: rotate(45deg);
    }
    &-right {
        transform: rotate(90deg);
    }
    &-bottom-right {
        transform: rotate(135deg) translateY(3px);
    }
    &-bottom {
        transform: rotate(180deg);
    }
    &-bottom-left {
        transform: rotate(225deg);
    }
    &-left {
        transform: rotate(270deg);
    }
    &-top-left {
        transform: rotate(325deg);
    }
}
