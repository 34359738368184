@import '../../../css/scss/vars';

.bc-engagement-rate-figure {
    position: relative;

    @media (max-width: 1199px) {
        margin: 40px 15px 28px;
    }

    @media (min-width: 1200px) {
        margin: 30px 15px 15px;
    }

    .bc-engagement-rate-figure__stars {
        position: absolute;

        @media (max-width: 1199px) {
            top: -19px;
            left: -2px;
        }

        @media (min-width: 1200px) {
            top: -32px;
            left: -4px;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .bc-engagement-rate-figure__wrapper {
        display: grid;
        grid-template-areas:
            '. secondValue'
            'firstValue secondValue';
        grid-column-gap: 8px;
    }

    .bc-engagement-rate-figure__value {
        padding: 8px;

        font-family: $f-medium;

        text-align: center;
        color: white;

        @media (max-width: 1199px) {
            font-size: 12px;
        }

        @media (min-width: 1200px) {
            font-size: 23px;
        }

        &--instagram {
            background-color: $instagram;
        }
        &--facebook {
            background-color: $facebook;
        }
        &--youtube {
            background-color: $youtube;
        }
        &--twitter {
            background-color: $twitter;
        }

        span {
            font-weight: normal;

            @media (max-width: 1199px) {
                font-size: 12px;
            }

            @media (min-width: 1200px) {
                font-size: 12px;
            }
        }

        &--first {
            grid-area: firstValue;

            @media (max-width: 1199px) {
                height: 37px;
                width: 50px;
            }

            @media (min-width: 1200px) {
                height: 64px;
                width: 95px;
            }
        }

        &--second {
            grid-area: secondValue;

            @media (max-width: 1199px) {
                height: 73px;
                width: 50px;
            }

            @media (min-width: 1200px) {
                height: 146px;
                width: 94px;
            }
        }
    }
}
