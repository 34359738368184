@import 'css/scss/_vars.scss';

.button {
    appearance: none;
    display: block;
    margin: 0 auto;
    padding: 8px 20px;
    padding: $smallSpace $largemargin;
    width: initial;

    border: none;
    border-radius: $radius;
    cursor: pointer;
    background-color: $instagram;
    font-family: $f-regular;
    font-size: 13px;
    text-align: left;

    /**
    * Fix a bug on iPad/mobile
    * On these devices `display`, `align-items`, and `justify-content` don't work well with <button>
    * Something is breaking them somewhere else in the CSS and even with !important this doesn't work. Because <div>
    * works fine with `display` and others, we wrap our content with it to fix the bug.
    */
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            fill: #fff;
            width: 22px;
            height: 22px;
            margin-right: 15px;
        }

        .label {
            display: inline-flex;
            flex-direction: column;
            .text {
                font-family: $f-medium;
                font-size: 14px;
                line-height: 20px;
                color: #ffffff;
            }
            .subText {
                margin-top: 2px;
                font-family: $f-light;
                color: #ffffff;
            }
        }
    }
}
