@import '../../../css/scss/vars';

.bc-post-estimations {
    &-card {
        margin: 6px;
        padding: 0 20px;
        box-shadow: $boxshadow;
        background: white;
        border: solid 1px white;
        display: flex;
        height: 72px;
        width: 100%;
        border-radius: $radius;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $break-mobile) {
            padding: 0 5px;
            .social-name__label {
                max-width: 37vw;
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
            }
        }

        &-no-prices {
            color: $darkblue;
            min-width: 270px;
            font-size: 14px;
            flex: 0.3;
            margin: 0;
            padding-right: 20px;

            @media (max-width: $break-mobile) {
                display: none;
            }
        }

        &-prices {
            flex: 0.1;
            min-width: 150px;
            display: flex;
            margin-right: 58px;
            justify-content: space-between;

            @media (max-width: $break-mobile) {
                min-width: 130px;
                margin-right: 0;
            }
        }
    }
}
