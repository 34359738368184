@import 'css/scss/_vars2.scss';

.tag {
  color: $kolblue;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  background-color: rgba($kolblue, 0.06);
  border-radius: $radius;

  > span {
    padding: 2px 2px 2px $tinySpace;

    @media only screen and (min-width: $l) {
      padding: 2px $smallSpace;
      font-size: 13px;
    }
  }
}
