@import '../../../../css/scss/vars';

$gridGap: 10px;

.post {
  font-family: $f-regular;

  .section {
    margin-top: $largeSpace;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $gridGap * 3;
    grid-auto-flow: dense;
    padding-top: 22px; // halfCircle

    @media (min-width: $break-tablet) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: $gridGap;
    }
  }

  .placeholder {
    margin-top: -$space;
  }
}
