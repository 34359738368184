@import '../../../../css/scss/vars';

.bc-analytics-stats__global-filters {
    position: relative;
    display: flex;
    justify-content: space-between;

    @include respond-to(mobile) {
        flex-direction: column;
        margin-bottom: $space;
    }

    @media (min-width: 768px) {
        justify-content: flex-end;
        align-items: center;
        margin-top: 28px;
        margin-bottom: $space;
    }

    .bnc_field_button_as_input button {
        height: 36px;
        line-height: normal;
    }
    .bnc_field_select_enhanced {
        svg {
            height: 19px;
            width: 19px;
        }
    }
}

.bc-analytics-stats-global-filters__label {
    margin-right: 7px;

    font-size: 13px;
    line-height: 15px;

    @include respond-to(mobile) {
        margin-bottom: 6px;
    }

    @media (max-width: 768px) {
    }
}

.bc-analytics-stats-global-filters__time-filter {
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to(mobile) {
        margin-top: 8px;
    }

    @media (min-width: 768px) {
        margin-left: 15px;
    }
}

.bc-analytics-stats-global-filters__time-filter-component {
    @include respond-to(mobile) {
        width: 100%;
    }

    @media (min-width: 768px) {
        width: 170px;
    }

    label {
        margin: 0;
    }
}
