@import '../../../css/scss/vars';

@mixin button-color($color) {
    border: 1px solid $color;
    color: $color;

    &:hover {
        background-color: $color;
        color: white;
    }
}

.bc-proposition-answered-mobile {
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);
}

.bc-proposition-answered-mobile__answer-container {
    padding: 20px 14px;
    min-height: 150px;

    border-left: 3px solid #979797;
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
}

.bc-proposition-answered-mobile__title {
    text-align: center;

    color: #4a4a4a;
    font-family: $f-medium;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 15px;
}

.bc-proposition-answered-mobile__answer {
    margin-top: 24px;

    color: #9d9d9d;
    font-family: $f-light;
    font-size: 13px;

    p {
        margin-bottom: 0;
    }
}

.bc-proposition-answered-mobile__control-buttons {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;

    text-align: right;
    justify-content: center;
    align-items: center;
}

.bc-proposition-answered-mobile__control-title {
    padding: 13px 20px;

    color: $greyBoulder;
    font-family: $f-light;
    font-size: 14px;
    letter-spacing: 1px;
}

.bc-proposition-answered-mobile__button {
    padding: 13px 20px;

    border: solid 1px grey;
    color: white;
    border-radius: 2px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: $f-light;
    font-size: 12px;

    &:not(:first-of-type) {
        margin-left: 10px;
    }

    &.bc-proposition-answered-mobile__button--selected {
        cursor: not-allowed;
        border: 1px solid #d9d9d9;

        background-color: #d9d9d9;

        &:hover {
            background-color: #d9d9d9;
            color: #fcfbfb;
        }
    }
}
