@import '../../../css/scss/vars';

button.btn-connect-instagram {
    appearance: none;
    display: block;
    padding: 0;
    margin: 0 auto;
    width: 250px;
    height: 50px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    background-color: #5d84aa;
    font-family: $f-regular;
    font-size: 13px;
    color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    &.btn-instagram--small {
        width: auto;
        height: auto;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 15px;
        padding-right: 20px;
    }
    /**
   * Fix a bug on iPad/mobile
   * On these devices `display`, `align-items`, and `justify-content` don't work well with <button>
   * Something is breaking them somewhere else in the CSS and even with !important this doesn't work. Because <div>
   * works fine with `display` and others, we wrap our content with it to fix the bug.
   */
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            fill: #fff;
            width: 22px;
            height: 22px;
            margin-right: 15px;
        }
    }
    &.is--business {
        text-align: left;
        height: 50px;
        width: 250px;
    }
}
