@import 'css/scss/_vars';

.row {
    display: flex;
    flex-wrap: wrap;
    padding: $space 0;
    align-items: center;
    border-bottom: 1px solid $divider;
    height: auto;

    > svg {
        width: 32px;
        height: 40px;
        fill: $darkblue;
        margin-right: $space;
    }

    .body {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        min-height: 40px;
        .network {
            margin: 0;

            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
        }
        .label {
            margin: 0;

            font-size: 12px;
            line-height: 18px;
        }
    }
    button {
        font-size: 14px;

        &.btSuccess {
            color: $green;
            background-color: $green5;
            border: none;

            &:focus {
                box-shadow: unset;
            }
        }
    }
    .cancel {
        margin-right: $space;
    }
    .arrow {
        width: fit-content;
        svg {
            width: 10px;
            height: 10px;
            margin: 0 $tinySpace;
        }
    }
    .break {
        flex-basis: 100%;
        height: 0;
    }
    .loaderContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: $space 0;
    }
    .snaIdentifierInput {
        &Container {
            margin: $space 0;
            font-size: 14px;
            width: 100%;
        }
        &Title {
            margin-bottom: $smallSpace;
        }
    }
}
