@import 'css/scss/_vars';

.radios {
    label {
        margin-bottom: unset;
    }
}

.select {
    label {
        display: unset;
        margin-bottom: unset;
    }
    select {
        color: inherit;
    }
}

.saveBtnContainer {
  display: flex;
  justify-content: center;
}
