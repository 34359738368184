@import 'css/scss/_vars';
.postContent {
    padding: 10px;
    word-break: break-word;
    font-size: 12px;
    line-height: 14px;

    em {
        font-style: inherit;
        font-family: $f-bold;
        border-bottom: 1px solid $lightblue;
    }
}
