@import 'css/scss/_vars';
@import 'css/scss/token';

.header {
    width: 327px; // To force title into 2 lines
}

.form {
    margin: $spacing_small 0;
    select {
        color: $darkblue;
    }

    &.onStepInfos {
        margin: 0 0 $spacing_small;
    }
}

.button {
    font-family: $f-D-DinExp-Bold;
}

.skipAndCreate {
    margin-top: $spacing_small;
}

.buttonLight {
  margin-top: $spacing_xxx-small;
  font-family: $f-D-DinExp;
}

.helperText {
    margin-top: $spacing_small;
    font-family: $f-D-DinExp;
}

.sliderText {
    font-family: $f-D-DinExp;
}

.footerText {
    margin-top: $spacing_small;

    text-align: center;
    color: $cornBlue;
}

.socialNetworksList {
    margin: $spacing_small auto;
}

.slideItem {
    text-align: center;

    .section {
        margin-top: $largemargin;
        @media (min-width: $break-tablet) {
            margin-top: $spacing_small;
        }
    }
    h4 {
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: $spacing_xxx-small;
        @media (min-width: $break-tablet) {
            font-size: 16px;
            margin-bottom: $space;
        }
    }
    img {
        &.post {
            height: 200px;
        }
        &.location,
        &.ageGender {
            width: 85%;
        }
        @media (min-width: $break-tablet) {
            &.location {
                width: 300px;
            }
            &.post {
                width: auto;
                height: 200px;
            }
        }
    }
}

.warningMessage {
    text-align: center;
    font-style: italic;
    font-size: 11px;
    line-height: 1.5;
    margin: $spacing_xxx-small 0;
    @media (min-width: $break-tablet) {
        font-size: 12px;
        margin: $space 0;
        padding: 0 $spacing_small;
    }
    .tooltipIcon {
        display: inline-block;
        margin-left: $spacing_xxx-small;
    }
}

.networkInstagramOnly {
    border-bottom: none !important;
    margin-bottom: $space;
}

.loader {
    @media (min-width: $break-tablet) {
        border-radius: $bigRadius;
        margin-top: -5px;
    }
}
