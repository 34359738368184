@import '../../../css/scss/vars';

.footer {
    width: 100%;
    height: 60px;
    background-color: #e5eaee;
    position: relative;
    overflow: hidden;
    z-index: 0;
    display: flex;
    align-items: center;
    padding: 0;
    &-wrapper {
        width: 100%;
    }
    @media (min-width: 768px) {
        padding: 0 40px;
    }

    ul {
        list-style: none;
        display: flex;
        margin: 0;
        justify-content: center;
        padding: 0 10px;

        @media (min-width: 768px) {
            justify-content: flex-end;
            padding: 0;
        }
        a {
            text-decoration: none;
            color: $darkblue;
            margin-left: 15px;
            @media (max-width: 740px) {
                font-size: 12px;
                padding-left: 0;
                margin-left: 5px;
            }
            margin-right: 15px;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .footer-logo {
        flex-grow: 1;
        svg {
            height: 30px;
            @media (max-width: 1199px) {
                display: none;
            }
        }
    }
}
