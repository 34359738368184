@import 'css/scss/_vars';
@import 'css/scss/_vars2.scss';

.wrapper {
  height: 40px;
  margin-right: $smallSpace;
  display: flex;
  align-items: center;
  justify-content: center;

  &.hide {
    opacity: 0;
  }

  .name {
    color: $kolnavy;
    font-family: $f-D-DinExp;
    font-weight: 400;
    font-size: 13px;
  }

  .picture {
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 5px;
    margin-right: 10px;
    background-color: $kolnavy;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
