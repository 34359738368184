@import '../../../css/scss/vars';

.bc-analytics-posts {
    @include pageContent;
}

.bc-analytics-posts__title {
    text-align: center;

    @include respond-to(mobile) {
        margin-top: 17px;
        margin-bottom: 21px;
        font-size: 20px;
        line-height: 45px;
    }

    @media (min-width: 768px) {
        margin-bottom: 37px;
        font-size: 30px;
        line-height: 35px;
    }
}

.bc-analytics-posts__sub-title {
    font-family: $f-medium;

    @include respond-to(mobile) {
        margin-bottom: 15px;

        font-size: 16px;
        line-height: 19px;
    }

    @media (min-width: 768px) {
        margin-bottom: 23px;

        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
    }
}

.bc-analytics-posts__all {
    padding-top: $largeSpace;
    scroll-margin: $largeSpace;
}

.bc-analytics-posts__empty-placeholder {
    margin-top: 26px;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to(mobile) {
        padding: 14px 12px;
        margin-bottom: 15px;
    }

    @media (min-width: 768px) {
        padding: 20px;
        margin-bottom: 20px;
    }

    &--transparent {
        border-left: none;
        background-color: transparent;
        box-shadow: none;
    }
}

.bc-analytics-posts-empty-placeholder__icon {
    margin-right: 10px;

    font-size: 0;

    svg {
        width: 21px;
        height: 21px;
        fill: #9b9b9b;
    }
}

.bc-analytics-posts-empty-placeholder__text {
    color: #9b9b9b;
    font-family: $f-light;

    @include respond-to(mobile) {
        font-size: 11px;
        text-align: center;
    }

    @media (min-width: 768px) {
        color: #9b9b9b;
    }
}

.bc-analytics-posts__placeholder {
    width: 100%;

    &:not(:first-of-type) {
        margin-top: 15px;
    }
}

.bc-analytics-posts__title {
    color: #4a4a4a;
    font-family: $f-light;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    flex-direction: row;
    margin-bottom: 15px;

    &:after {
        content: '';
        flex: 1 1;
        border-bottom: 1px solid rgba(74, 74, 74, 0.29);
        margin: auto auto auto 15px;
    }
}

.bc-analytics-posts__graphs {
    display: flex;
    justify-content: space-between;

    @include respond-to(mobile) {
        flex-direction: column;
        margin-top: 15px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        margin-top: 15px;
    }

    @media (min-width: 1200px) {
        margin-top: 20px;
    }
}

.bc-analytics-posts__graph-container {
    display: flex;
    justify-content: space-between;

    @include respond-to(mobile) {
        &:not(:first-of-type) {
            margin-top: 15px;
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        width: calc((100% / 2) - 5px);
    }

    @media (min-width: 1200px) {
        width: calc((100% / 2) - 10px);
    }
}

.bc-analytics-posts__posts-list-container {
    @media (min-width: 768px) and (max-width: 1199px) {
        margin: 0 -5px;
    }

    @media (min-width: 1200px) {
        margin: 0 10px;
    }
}

.bc-analytics-posts__pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
