@import 'css/scss/_vars.scss';

.bc-modal-addsna__input-list {
    margin-top: 12px;
    margin-bottom: 25px;

    text-align: left;

    .bc-modal-addsna__input-error {
        margin-bottom: 30px;
    }

    .input-blog .bnc_field_input_container input {
        padding-left: 15px;
    }
    .input-website .bnc_field_input_container input {
        padding-left: 15px;
    }
    .input-other_networks .bnc_field_input_container input {
        padding-left: 15px;
    }

    .bnc_field_input_gmap .bnc_field_input_container input {
        padding-left: 15px;
    }

    .bnc_field_input--multilines {
        height: unset;
        min-height: 80px;
        & .bnc_field_input_container {
            height: unset;
            min-height: 80px;
            & textarea {
                resize: none;
                min-height: 80px;
            }
        }
    }

    .bnc_field_input_container {
        line-height: 30px;
        height: 40px;
        display: inline-flex;
        width: 100%;
        .icon--left {
            height: 40px;
            display: inline-block;
            left: 0;
            line-height: 40px;
            padding: 0;
            margin: 0;
            width: 40px;
            padding: 3px 12px;
            border-right: none;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
        input {
            padding-left: 50px;
            height: 40px;
            line-height: 40px;
            &:disabled {
                padding-right: 40px;
                background-color: $disabled;
                cursor: not-allowed;
            }
            &::placeholder {
                font-style: italic;
            }
        }
    }

    .bnc_field:not(:first-of-type) {
        margin-top: 10px;
    }

    label {
        font-family: $f-light;
        padding: 0;
        margin: 0;
        height: 40px;
    }

    .bnc_field .help-text {
        bottom: -5px;
    }

    .input-facebook .icon--left {
        background: $facebook;
    }

    .input-youtube .bnc_field_input_container .icon--left {
        background: $youtube;
        padding: 3px 11px;
    }

    .input-twitter .icon--left {
        background: $twitter;
    }

    .input-tiktok .icon--left {
        background: $tiktok;
    }

    .input-snapchat .icon--left {
        background: $snapchat;
    }

    .input-pinterest .icon--left {
        background: $pinterest;
        padding: 4px 10px;
    }

    .input-twentyone_buttons .icon--left {
        background: $twentyone_buttons;
        padding: 4px 10px;
    }

    .input-twitch .icon--left {
        background: $twitch;
        padding: 4px 11px;
    }
}

.bc-modal-content-input-list__label {
    margin-bottom: 3.5px;

    color: #003057;
    font-family: $f-medium;
    font-size: 12px;
    text-transform: uppercase;
}
