@import '../../../css/scss/vars';

.social-name {
    display: flex;
    align-items: center;
    margin: 0;
    width: 100%;
    & svg {
        width: 20px;
        flex: none;
    }
    &__label {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        padding-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.facebook {
            color: $darkblue;
        }
        &.youtube {
            color: $red;
        }
        &.instagram,
        &.instagram_stories {
            color: $instagram;
            &.isIgtv {
                color: $instagramIgtv;
            }
        }
        &.twitter {
            color: $lightblue;
        }
    }
}
