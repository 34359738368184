@import 'css/scss/_vars2.scss';

.wrapper {
  flex: 0 0 auto;
  width: 100%;
  padding: $smallSpace $tinySpace;
  border-bottom: $border;
  cursor: pointer;
  transition: opacity 200ms $transition, transform 200ms $transition;
  will-change: transform;

  &.active .content {
    background-color: #F2F4F6;
  }

  @media only screen and (min-width: $l) {
    padding: $smallSpace $space;
  }
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $smallSpace;
  background-color: white;
  border-radius: $bigRadius;
  transition: background-color $transition;

  &:hover {
    background-color: rgba(#F2F4F6, .5);
  }
}

.rightContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
  overflow: hidden;
}

.label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 9;
}

.name {
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  color: $kolnavy;
  max-width: 50%;

  @media only screen and (min-width: $l) {
    font-size: 15px;
  }
}

.participantsSize {
  margin-left: $tinySpace;
  font-style: italic;
}

// CardMessage
.cardMessage {
  .body {
    display: flex;
    flex-flow: column wrap;
    flex-grow: 2;
    margin-right: $smallSpace;
    overflow: hidden;

    > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .tag {
      max-width: 50%;
      margin-left: 2px;

      @media only screen and (min-width: $l) {
        // max-width: initial;
        margin-left: $smallSpace;
      }
    }

    .preview {
      width: 100%;
      font-size: 13px;
      color: $kolnavy;

      & > div {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        overflow: hidden;
        max-height: 20px;

        & * {
          flex: none;
          width: 100%;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        & li {
          line-height: 1.5;
        }
      }

      @media only screen and (min-width: $l) {
        font-size: 14px;
      }

      .truncateWrapper {
        width: 100%;
        .draftTag {
          color: $kolblue;
          margin-right: $tinySpace;
        }
      }
    }
  }

  .data {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    min-width: fit-content;

    .timeago {
      font-size: 10px;
      text-align: right;
      color: $kolnavy;

      @media only screen and (min-width: $l) {
        font-size: 12px;
      }
    }

    .unreadMessages {
      width: 20px;
      height: 20px;
      background-color: $kolpink;
      border-radius: 50%;
      color: white;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: $tinySpace;
    }

    .checkIcon {
      margin-top: $tinySpace;
    }
  }

  .deleteIcon {
    margin-left: $tinySpace;
  }
}

// CardProfile
.cardProfile {
  .body {
    display: flex;
    flex-flow: column wrap;
    flex-grow: 2;
    margin-right: $smallSpace;

    > div {
      position: relative;
      display: flex;
      align-items: center;
    }
    .status {
      font-size: 13px;
      color: #DD2727;

      @media only screen and (min-width: $l) {
        font-size: 14px;
      }
    }
  }
}


