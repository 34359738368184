@import '../../../css/scss/vars';

.recap {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    background-color: white;
    box-shadow: $boxshadow;
    border-radius: 4px;
    color: $darkblue;

    > * {
        flex: 1;
    }

    @include respond-to(mobile) {
        flex-direction: column;
    }
}
.legend {
    align-self: flex-end;
    font-size: 12px;
    margin: $smallmargin 0 $mediummargin;

    @include respond-to(mobile) {
        align-self: flex-start;
    }
}
