@import '../../../css/scss/vars';

@mixin colors {
    &-positive {
        color: $green4;
    }
    &-negative {
        color: $red;
    }
    &-equal {
        color: $materialBlue;
    }
}

.bc-metrics-card {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    & p {
        margin: 0;
        line-height: 20px;
    }
    &-rate {
        font-size: 13px;
        font-weight: 700;
        flex: 0.4;
        height: 20px;
        white-space: nowrap;
        font-family: $f-bold;
        @include colors;
    }
    &-evolution {
        font-size: 10px;
        flex: 0.4;
        height: 19px;
        display: inline-flex;
        justify-content: flex-start;
        @include colors;
        white-space: nowrap;
        &-equalsign {
            font-size: 15px;
            width: 15px;
        }
    }
}
