@import '../../../css/scss/vars';

.bc-content-header {
  color: $darkblue;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0 0 24px;

  .bc-content-header_title {
    display: flex;
    align-items: center;

    .bc-content-header_icon {
      margin-right: $smallSpace;
    }

    h3 {
      font-size: 24px;
      line-height: 21px;
      text-align: left;
      margin: 0;
      font-family: $f-medium;
    }
  }

  & p {
    margin: 12px 0 0 0;
    font-size: 14px;
    line-height: 1.4;
    color: $subTitle;
  }
}
