@import 'css/scss/_vars';

.card {
    position: relative;
    height: fit-content;
    box-shadow: $boxshadow;
    background: white;
    border-radius: $radius;

    a {
        display: flex;
        width: 100%;
    }

    .rankDate {
        display: flex;
        font-size: 11px;
        padding: 12px;
    }

    .socialName {
        padding: 0 12px;
    }

    .rank {
        background: $lightblue;
        color: white;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        border-radius: 22px;
        margin-top: -34px;
    }

    .day {
        margin: 0 10px;
        font-family: $f-bold;
    }

    .body {
        width: 100%;
        @media (max-width: $break-mobile) {
            display: flex;
        }
    }

    .engagement {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        border-bottom: $border;
        @media (max-width: $break-mobile) {
            border: none;
        }

        .engagementRate {
            width: 110px;
            height: 32px;
            border-radius: $radius;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            padding: 0 $smallSpace;
            font-family: $f-bold;

            &Percent {
                font-size: 10px;
                font-family: $f-medium;
            }

            &Value {
                white-space: nowrap;
            }

            &.top {
                background: $lightGreen2;
                color: $green4;
            }

            &.flop {
                background: $lightRed;
                color: #fb213b;
            }

            @media (max-width: $break-mobile) {
                flex-direction: column;
                padding: $smallSpace;
                height: auto;
            }
        }

        .stats {
            display: flex;
            flex-direction: column;
            font-size: 9px;
            margin-left: 20px;

            .stat {
                display: flex;
                flex-direction: row;
                margin-bottom: 4px;
                align-items: center;
                justify-content: space-between;
                svg {
                    width: 12px;
                    fill: $darkblue;
                    margin-right: 6px;
                }
                .statInteraction {
                    display: flex;
                    align-items: center;
                }
                .statMetric {
                    color: $lightblue;
                    font-family: $f-bold;
                    margin-left: 10px;
                    white-space: nowrap;
                }
            }
        }
    }

    .image {
        padding: $reducedSpace;
        position: relative;
        img {
            width: 100%;
            max-width: 100%;
            object-fit: cover;
        }
        .imageIcon {
            position: absolute;
            top: $space;
            right: $space;
        }
    }

    .text {
        padding: 12px;
        font-size: 11px;
        line-height: 13px;
        @media (max-width: $break-mobile) {
            display: none;
        }
    }
}
