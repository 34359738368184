@import 'css/scss/_vars';

.modal {
    position: absolute;

    color: $darkblue;
    min-width: auto;
    max-width: 100%;

    @media only screen and (min-width: $break-tablet) {
        position: relative;
        box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3);
    }
}

.modalContent {
    display: flex;
    align-items: stretch;
    text-align: center;
    flex-direction: column;
}

.title {
    font-size: 18px;
    font-weight: 700;
    font-family: $f-header;
    margin-bottom: $space;
}

.subtitle {
    margin-bottom: $largeSpace;
}

.img {
    margin-bottom: $largeSpace;
    width: 640px;
    max-width: 100%;
}

.primaryButton {
    margin-bottom: $reducedSpace;
}

//  INFO: overwrite default Material UI styles, can't be done with className
.root {
    border-radius: 12px !important;
    @media only screen and (max-width: $break-tablet) {
        border-radius: 0 !important;
        max-width: 100% !important;
        max-height: initial !important;
        margin: 0 !important;
        width: 100%;
        height: 100%;
        justify-content: center;
    }

    @include respond-to(13inches) {
        // Overwrite a specific div style
        > div:first-child > div:first-child {
            max-height: unset;
        }
    }
}
