@import 'css/scss/_vars2.scss';

.profileDetails {
  padding-top: 60px;
  border-left: $border;
}
.sidebarProfile {
  width: 375px;
}

.container {
  position: fixed;
  width: 200vw;
  height: calc(100vh - 60px);
  top: 60px;
  left: 0;
  box-sizing: border-box;
  overflow: hidden;
  border-top: $border;

  @media only screen and (min-width: $l) {
    width: 100vw;
    top: 60px;
  }

  > div {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    will-change: transform;

    @media only screen and (min-width: $l) {
      transform: translateX(0);
    }
  }
}
