@import 'css/scss/_vars2.scss';

.dropdown {
  max-width: calc(100% - 112px);
  margin-top: 2px;

  .subHeaderClassName {
    text-transform: none;
    color: #638198;
    font-size: 13px;
  }

  .item {
    &.selected {
      background-color: $kolblue;
      color: $white;
    }
  }

  .button {
    display: block;
    position: relative;
    width: 100%;
  }
}
