@import '../../../css/scss/vars';

@mixin button-color($color) {
    border: 1px solid $color;
    color: $color;

    &:hover {
        background-color: $color;
        color: white;
    }
}

.bc-proposition-answer-mobile {
    margin-top: 10px;
    width: 100%;
    height: 46px;
}

.bc-proposition-answer-mobile__answer-button {
    width: 100%;
    padding: 15px;

    border: 1px solid #1f2f39;
    border-radius: 2px;
    background-color: #1f2f39;

    color: #ffffff;
    font-family: $f-light;
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
}

.bc-proposition-answer-mobile__answer-button-container {
    width: 100%;

    &--sticky {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 10px 14px;

        box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.18);
        background-color: white;
    }
}

.bc-proposition-answer-mobile-modal {
    padding: 27px 14px;
}

.bc-proposition-answer-mobile-modal__title {
    opacity: 0.5;
    color: $greyBoulder;
    font-family: $f-light;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 16px;
}

.bc-proposition-answer-mobile-modal__textarea {
    margin-top: 10px;

    color: $greyBoulder;
    font-family: $f-light;
    font-size: 13px;
    line-height: 17px;
}

.bc-proposition-answer-mobile-modal__control-buttons {
    padding-top: 20px;
}

.bc-proposition-answer-mobile-modal__control-title {
    text-align: center;

    color: $greyBoulder;
    font-family: $f-light;
    font-size: 14px;
    letter-spacing: 1px;
}

.bc-proposition-answer-mobile-modal__button {
    padding: 13px 20px;
    width: 100%;

    border: solid 1px grey;
    color: white;
    border-radius: 2px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: $f-light;
    font-size: 12px;

    margin-top: 10px;

    &.bc-proposition-answer-mobile-modal__button--yes {
        @include button-color(#2dbc5e);
    }

    &.bc-proposition-answer-mobile-modal__button--discuss {
        @include button-color(#446378);
    }

    &.bc-proposition-answer-mobile-modal__button--no {
        @include button-color(#ff3333);
    }

    &.bc-proposition-answer-mobile-modal__button--disabled {
        cursor: not-allowed;
        border: 1px solid #d9d9d9;

        background-color: #fcfbfb;
        color: #d9d9d9;

        &:hover {
            background-color: #fcfbfb;
            color: #d9d9d9;
        }
    }

    &.bc-proposition-answer-mobile-modal__button--selected {
        cursor: not-allowed;
        border: 1px solid white;

        background-color: #d9d9d9;
        color: #fcfbfb;

        &:hover {
            background-color: #d9d9d9;
            color: #fcfbfb;
        }
    }
}
