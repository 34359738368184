@import '../../../css/scss/vars';

.bc-post-estimations {
    margin: 5px auto 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &__warning {
        width: 16px;
        height: 16px;
        padding-top: 4px;
    }

    & header {
        color: $darkblue;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 20px 0;

        h3 {
            font-size: 18px;
            line-height: 21px;
            text-align: left;
            margin: 0 0 5px 0;
        }

        p {
            font-size: 13px;
            line-height: 15px;
            margin: 0;
        }
    }

    section {
        display: flex;
    }
}
