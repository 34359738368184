@import 'css/scss/_vars';

.header {
    margin: 0;

    text-transform: uppercase;
    font-family: $f-header;
    font-size: 12px;
    line-height: 18px;
    color: $cornBlue;
}

.title {
    font-family: $f-title;
    font-size: 24px;
    line-height: 36px;
}
