@import '../../../css/scss/vars';

.root {
  &:hover {
    .notchedOutline {
      border-color: #CCD6E3 !important;
      outline-color: #CCD6E3 !important;
      outline-offset: 0;
    }
  }
  &:focus, &:focus-within {
    .notchedOutline {
      border-width: 1px !important;
      outline-offset: 0;
      outline: none;
      border-color: $lightblue !important;
      box-shadow: 0 0 0 3px #CCFFFA !important
    }
  }
}
