@import '../../../css/scss/vars';

.bc-analytics-stats {
    @include pageContent;
}

.bc-analytics-stats__title {
    font-weight: 700;
    line-height: 45px;

    @include respond-to(mobile) {
        margin: $space 0;
        font-size: 28px;
    }

    @media (min-width: 768px) {
        margin: $largeSpace 0;
        font-size: 32px;
        text-align: center;
    }
}

.bc-social-stats__chart-placeholder {
    width: 100%;

    &:not(:first-of-type) {
        @include respond-to(mobile) {
            margin-top: 15px;
            width: 100%;
        }

        @media (min-width: 768px) {
            margin-top: 30px;
        }
    }
}

.bc-social-stats__charts-title {
    font-family: $f-medium;

    @include respond-to(mobile) {
        margin-top: 15px;
        margin-bottom: 15px;

        font-size: 16px;
        line-height: 19px;
    }

    @media (min-width: 768px) {
        margin-top: 33px;
        margin-bottom: 23px;

        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
    }
}

.bc-social-stats__chart-card-container {
    @include respond-to(mobile) {
        margin-top: 15px;
    }

    @media (min-width: 768px) {
        margin-top: 30px;
    }
}

.bc-analytics-community__placeholder {
    width: 100%;

    &:not(:first-of-type) {
        margin-top: 15px;
    }
}

.bc-analytics-community__chart-container {
    margin-top: 15px;
}

.bc-analytics-community-chart-container__title {
    font-family: $f-medium;

    @include respond-to(mobile) {
        margin-top: 43px;
        margin-bottom: 8px;

        font-size: 16px;
        line-height: 19px;
    }

    @media (min-width: 768px) {
        margin-top: 33px;
        margin-bottom: 13px;

        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
    }
}

.bc-analytics-community-chart-container__text {
    font-family: $f-regular;

    font-size: 13px;
    line-height: 15px;

    @include respond-to(mobile) {
        margin-bottom: 22px;
    }

    @media (min-width: 768px) {
        margin-bottom: 23px;
    }
}
