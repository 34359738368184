@import 'css/scss/_vars';

.wrapper {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: $boxshadow;
    border-radius: $radius;
    margin-bottom: 16px;
}

.header {
    padding: 12px;
    display: flex;
    justify-content: space-between;

    @include respond-to(mobile) {
        flex-direction: column;
    }

    .title {
        @include boxTitle;
    }
}

.subTitle {
    font-size: 12px;
    font-style: italic;
    line-height: 14px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.seeLink {
    display: flex;
}

.seeButton {
    margin: 0 auto;
    svg {
        transform: rotate(45deg);
    }
}
.empty {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.emptyPlaceholder {
    font-size: 16px;
    margin-bottom: 20px;
}
.cardEmpty {
    width: auto;
    min-height: auto;
    margin: 0 auto;
    button {
        height: auto;
        min-height: auto;
        padding: 8px;
    }
    @include respond-to(mobile) {
        width: auto;
        margin: 0 16px;
    }
}
