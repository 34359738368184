@import '../../../css/scss/vars';

.recapItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: $largemargin;

    @include respond-to(mobile) {
        &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 48, 87, 0.2);
        }
    }

    > * {
        margin: $smallmargin auto;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        text-align: center;

        text-transform: uppercase;
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: $mediummargin auto;

        .main {
            font-size: 46px;
            font-weight: bold;
            text-align: center;

            &.isHashtag {
                font-size: 26px;
            }
            > .suffix {
                font-size: 20px;
                padding: 2px;
            }
        }
    }
}
