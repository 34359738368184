@import '../../css/scss/vars';

.faq {
    @include pageMargin;
    padding: 15px;
    max-width: 1100px;

    font-size: 16px;
    line-height: 21px;

    .content {
        @include paper;
        margin-top: $space;
        padding: $space;

        .cta {
            padding: $largeSpace 0 $space;

            font-size: 18px;
            text-align: center;
            > * {
                margin: $smallSpace;
            }
        }
    }
}

.title {
    @include title;
}

.desc {
    margin: $largeSpace 0;
    text-align: center;
}

.question {
    font-weight: 700;
}

.answer {
    margin: 1em 0 $largeSpace;
    ul,
    li {
        padding: revert;
        margin: revert;
    }
}
