@import '../../../css/scss/vars';

.emptyPlaceholder {
  img {
    width: 100px;
  }

  @media (min-width: $break-tablet) {
    img {
      width: 140px;
    }
  }
}
