@import '../../../../css/scss/vars';

.bc-analytics-community-geo-chart {
    padding: 30px 20px;

    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(10, 31, 68, 0.1);
}

.bc-analytics-community-geo-chart__title {
    margin-bottom: 30px;

    font-family: $f-light;
    font-size: 15px;
    text-transform: uppercase;
}

.bc-analytics-community-geo-chart__geo-chart {
    .bc-google-chart-container {
        //width: 100%;

        .bc-google-chart {
            margin-left: 80px;
            margin-right: 80px;
        }
    }
}

.bc-analytics-community-geo-chart__geo-chart-placeholder {
    text-align: center;
    font-family: $f-light;

    @include respond-to(mobile) {
        font-size: 12px;
    }
}

.bc-analytics-community-geo-chart__legends {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    @include respond-to(mobile) {
        flex-direction: column;
    }

    @media (min-width: 768px) {
        margin-left: 65px;
        margin-right: 65px;
    }
}

.bc-analytics-community-geo-chart__legend {
    display: flex;

    @include respond-to(mobile) {
        flex-direction: row;
        justify-content: space-between;

        &:not(:first-child) {
            margin-top: 25px;
        }
    }

    @media (min-width: 768px) {
        flex-direction: column;
        width: 120px;

        &:not(:first-child) {
            margin-left: 32px;
        }
    }
}

.bc-analytics-community-geo-chart-legend__country {
    display: flex;
    margin-bottom: 7px;

    @include respond-to(mobile) {
        width: 100%;
    }

    img {
        height: 20px;
        width: 28px;
        margin-right: 15px;
        border-radius: 2px;
    }
}

.bc-analytics-community-geo-chart-legend__country-metric {
    font-family: $f-regular;
    font-size: 20px;
    line-height: 24px;
}

.bc-analytics-community-geo-chart-legend__country-name {
    font-family: $f-regular;
    font-size: 9px;
}

.bc-analytics-community-geo-chart-legend__cities {
    @include respond-to(mobile) {
        width: 100%;
    }
}

.bc-analytics-community-geo-chart-legend__city {
    display: flex;

    opacity: 0.6;
    font-family: $f-light;
    font-size: 9px;
    line-height: 13px;
    border-left: 1px solid #d2d2d2;
}

.bc-analytics-community-geo-chart-legend__city-metric {
    text-align: right;
    width: 34px;
    margin-right: 10px;
}

.bc-analytics-community-geo-chart__bottom-infos {
    font-family: $f-light;
    font-size: 10px;
    line-height: 19px;
    text-align: right;
}
