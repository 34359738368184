@import 'css/scss/_vars2.scss';

.wrapper {
  a {
    display: flex;
    color: $kolnavy;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }

  &.editable {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $m) {
      flex-direction: row;
    }
    .item {
      display: flex;
      align-items: center;
      width: fit-content;
      padding-left: $smallSpace;
      margin: $smallSpace 0 0 $smallSpace;
      background: $kolnavy;
      border-radius: $radius;
      color: $white;
  
      .name {
        flex-grow: 2;
        font-size: 12px;
      }
    }
  }

  &.alignRight {
    .item {
      justify-content: flex-end;
    }
  }
  .icon {
    margin-right: $smallSpace;
  }
}