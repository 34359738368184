@import '../../../../css/scss/vars';

.bc-analytics-stats__chart-card {
    user-select: none;

    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);

    @include respond-to(mobile) {
        padding: 16px 10px 12px;
    }

    @media (min-width: 768px) {
        padding: 40px 20px 26px;
    }

    .bc-chart-tooltip {
        border-radius: 2px;
        padding: 10px;

        @include respond-to(mobile) {
            margin-top: 15px;
        }

        @media (min-width: 768px) {
            box-shadow: 0 0 18px 0 rgba(10, 31, 68, 0.12);
        }
    }

    .bc-chart-tooltip__title {
        font-family: $f-medium;
        font-size: 11px;
    }

    .bc-chart-tooltip__label {
        font-family: $f-light;
        font-size: 11px;
        white-space: nowrap;
        color: $darkblue;
    }
}

.bc-analytics-stats-chart-card__header {
    display: flex;

    @include respond-to(mobile) {
        margin-bottom: 18px;
    }

    @media (min-width: 768px) {
        margin-bottom: 60px;
    }
}

.bc-analytics-stats-chart-card__title {
    display: flex;

    font-family: $f-light;
}

.bc-analytics-stats-chart-card__title-picto {
    @include respond-to(mobile) {
        margin-right: 5px;
        width: 14px;
        height: 14px;
    }

    @media (min-width: 768px) {
        margin-right: 8px;
        width: 21px;
        height: 21px;
    }
}

.bc-analytics-stats-chart-card__title-network {
    text-transform: uppercase;

    @include respond-to(mobile) {
        font-size: 12px;
    }

    @media (min-width: 768px) {
        font-size: 15px;
    }
}

.bc-analytics-stats-chart-card__title-pseudo {
    font-family: $f-light;
    font-size: 10px;
    line-height: 11px;
}

.bc-analytics-stats-chart-card__filters {
    margin-left: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to(mobile) {
        display: none;
    }
}

.bc-analytics-stats-chart-card__filter {
    border: none;
    background-color: transparent;
    cursor: pointer;

    color: $darkblue;
    font-family: $f-light;
    text-align: center;
    border: 0.7px solid #dddddd;
    white-space: nowrap;

    &:first-of-type {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }

    &:last-of-type {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    @include respond-to(mobile) {
        width: 100%;
        padding: 8px;

        font-size: 12px;
    }

    @media (min-width: 768px) {
        min-width: 43px;
        padding: 7px 10px;

        font-size: 8.4px;
    }

    &--selected {
        color: white;
        background-color: $lightblue;
        border-color: $lightblue;
    }
}

.bc-analytics-stats-chart-card__body {
    @media (min-width: 768px) {
        margin: 0 20px;
    }
}

.bc-analytics-stats-chart-card__legends {
    display: flex;
    justify-content: center;
    padding-top: 12px;
}

.bc-analytics-stats-chart-card__legend {
    display: flex;
    align-items: center;
    padding: 5px 7px;
}

.bc-analytics-stats-chart-card__legend-line {
    width: 16px;
    height: 2px;
    margin-right: 7px;
}

.bc-analytics-stats-chart-card__legend-area {
    width: 10px;
    height: 10px;
    margin-right: 7px;
}

.bc-analytics-stats-chart-card__legend-text {
    color: $darkblue;
    font-family: $f-regular;
    font-size: 10px;
    opacity: 0.5;
}

.bc-analytics-stats-chart-card__placeholder {
    text-align: center;
    font-family: $f-light;

    @include respond-to(mobile) {
        font-size: 12px;
    }
}
