@import 'css/scss/_vars2.scss';

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  .button {
    max-width: 100%;

    &.open {
      svg {
        transform: rotate(-180deg);
      }
    }

    svg {
      transition: transform 300s ease-in-out;
      will-change: transform;
    }
  }

  .collapse {
    position: absolute;
    left: 0;
    background-color: #fff;
    z-index: 30;
  }

  .collapseWrapper {
    padding: $tinySpace;
    border: $border;
    border-radius: $radius;
    box-shadow: 0 8px 10px 0 rgba($kolnavy, 0.1);
    overflow: hidden;
  }

  .subHeader {
    padding: $smallSpace $tinySpace;
    text-transform: uppercase;
    color: $kolnavy;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
  }

  .items {
    position: relative;
    max-height: 182px;
    overflow-y: auto;
  }

  .item {
    padding: $tinySpace;
    width: 100%;
    border-radius: $radius;

    &:hover, &:focus {
      background: #F2F4F6;
    }
  }
}
