@import '../../../css/scss/vars';

.bc-analytics-graph-card {
    min-height: 250px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(10, 31, 68, 0.1);
}

.bc-analytics-graph-card__label {
    margin: 20px 20px auto;

    text-transform: uppercase;
    font-family: $f-light;
    font-size: 11px;
    line-height: 13px;
}

.bc-analytics-graph-card__chart {
}

.bc-analytics-graph-card__legends {
    display: flex;
    justify-content: space-around;
    margin: auto 20px 15px;
}

.bc-analytics-graph-card__legend {
    font-family: $f-light;
    font-size: 10px;
    line-height: 19px;

    svg {
        width: 10px;
        height: 10px;
        margin-right: 5px;
    }
}

.bc-analytics-graph-card .google-visualization-tooltip {
    //normalizer
    border: none;
    background: none;
    box-shadow: none;
}

.bc-analytics-graph-card__tooltip {
    padding: 10px;

    border-radius: 2px;
    background: white;
    box-shadow: 0 0 18px 0 rgba(10, 31, 68, 0.12);

    h3 {
        margin: 0 0 7px;

        font-family: $f-medium;
        font-size: 11px;
        white-space: nowrap;
    }

    p {
        margin: 0;

        font-family: $f-light;
        font-size: 11px;
        white-space: nowrap;
    }
}
