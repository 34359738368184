@import '../../../css/scss/vars';

.partenariats {
    min-height: 75vh;
    h3 {
        text-align: center;

        @include respond-to(mobile) {
            margin: 10px 0;
            font-size: 20px;
            line-height: 45px;
        }

        @media (min-width: 768px) {
            margin-bottom: 37px;
            font-size: 30px;
            line-height: 35px;
        }
    }
    &__content {
        @include respond-to(mobile) {
            width: 100%;
        }
        margin: auto;
        &__header {
            color: $darkblue;
            display: inline-flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding: 0;
            margin: 10px 0;
            @include respond-to(mobile) {
                width: 100%;
                flex-direction: column;
                padding: 10px 5%;
            }
            h3 {
                font-size: 18px;
                line-height: 35px;
                margin: 0;
                padding: 0;
                text-align: left;
            }
            .filter {
                width: 230px;
                display: inline-flex;
                height: 35px;
                line-height: 35px;
                @include respond-to(mobile) {
                    width: 100%;
                    padding: 10px 0;
                    justify-content: space-between;
                    & .time-filter-component {
                        width: 50%;
                    }
                }
                .label {
                    font-size: 12px;
                    color: $darkblue;
                    font-family: $f-400;
                    width: 160px;
                    height: 36px;
                    line-height: 36px;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
}
