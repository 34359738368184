@import 'css/scss/_vars2.scss';

.rdw-option-active {
  box-shadow: none;
  img {
    opacity: 1 !important;
    filter: invert(62%) sepia(82%) saturate(2304%) hue-rotate(150deg) brightness(100%) contrast(106%) !important;
  }
}
.public-DraftEditorPlaceholder-root {
  color: #7F97B0;
}

.public-DraftStyleDefault-block, .public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
  margin: $tinySpace 0;
}

